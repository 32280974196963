import React, { useEffect, useState } from 'react'
import { ApiDeleteCall, ApiGetCall, ApiPostCall, ApiUpdateCall, CheckValidation, HideLoder, ShowLoder, getUrlParameter, isValidEmail } from '../Component/Common'
import { Modal } from 'react-bootstrap';
import AlertsComp from '../Component/AlertsComp';

function FranchiseUser(props) {
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [FranchiseData, setFranchiseData] = useState('')
    const [productList, setProductList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [IsUserModal, setIsUserModal] = useState(false);
    const [addUpdateField, setAddUpdateField] = useState({
        name: '',
        email: '',
        password: ''
    });
    const [emailError, setEmailError] = useState("");
    const [deletePopup, setDeletePopup] = useState(false);
    const [Userid, setUserid] = useState('')
    const [EditModal, setEditModal] = useState(false)
    const [EditFlag, setEditFlag] = useState('')
    useEffect(() => {
        GetUserList();
    }, [])
    async function GetUserList() {
        setLoading(true);
        ApiGetCall("/franchise-user?FranchiseId=" + props.FId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.rows?.length != 0) {
                        setUserList(responseRs?.data?.rows);
                    } else {
                        setUserList([]);
                    }
                }
            }
        });
    }
    async function addUserCall(flag) {
        var isFormValid = CheckValidation({ formID: "UserForm" });
        if (addUpdateField.email == null) {
            addUpdateField.email = "";
        }
        if (addUpdateField.email !== "") {
            if (!isValidEmail(addUpdateField.email)) {
                setEmailError('*Invalid Email')
                isFormValid = false;
            } else {
                setEmailError('')
            }
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            name: addUpdateField.name,
            email: addUpdateField.email,
            password: addUpdateField.password,
            FranchiseId: props.FId,
        });
        await ApiPostCall("/franchise-user", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(
                        <AlertsComp show={true} variant="success" msg={"User Added Successfully."} />
                    );
                    props.setUserModal(false);
                    setTimeout(() => {
                        GetUserList();
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }
    async function DeleteProductCall() {
        setLoading(true);
        await ApiDeleteCall("/franchise-user/" + Userid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"User Deleted Successfully."} />);
                    setDeletePopup(false);
                    setTimeout(() => {
                        GetUserList();
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });

    }
    async function GetElementByID(id) {
        setEditModal(true)
        setLoading(true);
        ApiGetCall("/franchise-user/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                const sugData = responseRs?.data;
                setLoading(false);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.length != 0) {
                        setAddUpdateField({ ...addUpdateField, name: sugData.name, email: sugData.email, password: sugData.password });
                    }
                }
            }
        });
    }
    async function UpdateFUserCall() {
        var isFormValid = CheckValidation({ formID: "UserForm" });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            name: addUpdateField.name,
            email: addUpdateField.email,
            password: addUpdateField.password,
        });
        await ApiUpdateCall("/franchise-user/" + Userid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"User Updated Successfully."} />);
                    setEditModal(false);
                    setEditFlag('')
                    setAddUpdateField({ ...addUpdateField, name: '', email: '', password: '' }); 
                    setEmailError('');
                    setTimeout(() => {
                        GetUserList()
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }
    return (
        <div>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div>
                <table className="table data-table mt-3">
                    <thead className="GridHeader">
                        <tr>
                            <th scope="col" className="fw-600"> Name </th>
                            <th scope="col" className="fw-600"> Email </th>
                            <th scope="col" className="fw-600 text-center"> Action  </th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList.length != 0 ? (
                            userList.map((item, i) => {
                                return (
                                    <tr key={i} className="OddEvenClass tableBorderHide">
                                        <td scope="col">{item.name}</td>
                                        <td scope="col">{item.email}</td>
                                        <td scope="col" className="text-center py-12"  >
                                            <img src="/assets/images/EditIconNew.svg" title="Edit Product" className="img-fluid px-1 cursor-pointer"
                                                onClick={(e) => { setUserid(item.id); setEditFlag(1); setEditModal(true); GetElementByID(item.id) }} />
                                            <img src="/assets/images/DeleteIcon.svg" title="Delete Product" className="img-fluid px-1 cursor-pointer"
                                                onClick={(e) => { setUserid(item.id); setDeletePopup(true); }} />
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr className="text-center">  <td colSpan={15}>No Record Found</td>  </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {(EditFlag == 1 ? EditModal == true : props.UserModal == true) && (
                <Modal show={EditFlag == 1 ? EditModal : props.UserModal} size="lg">
                    <Modal.Header closeButton onClick={(e) => { EditFlag == 1 ? setEditModal(false) : props.setUserModal(false); setAddUpdateField({ ...addUpdateField, name: '', email: '', password: '' }); setEmailError(''); setEditFlag('') }}>
                        <Modal.Title>
                            {EditFlag == 1 ? "Edit Franchise User" : "Add Franchise User"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div id="UserForm" className="parent">
                            <div className="row">
                                <div className="col-md-6 py-2">
                                    <div className="fw-600 mb-1"> User Name</div>
                                    <input className="form-control" value={addUpdateField.name} required
                                        autoComplete="off" name="productName"
                                        onChange={(e) => {
                                            setAddUpdateField({ ...addUpdateField, name: e.target.value, });
                                        }} />
                                    <span className="form-text invalid-feedback"> *required </span>
                                </div>

                                <div className="col-md-6 py-2">
                                    <div className="fw-600 mb-1">Email</div>
                                    <input type='text' className="form-control" value={addUpdateField.email}
                                        autoComplete="off" name="productName"
                                        onChange={(e) => {
                                            setAddUpdateField({ ...addUpdateField, email: e.target.value, });
                                        }} />
                                    <span className="Emailinvalid">
                                        {emailError}
                                    </span>
                                </div>
                                <div className="col-md-6 py-2">
                                    <div className="fw-600 mb-1">Password</div>
                                    <input type='password' className="form-control" value={addUpdateField.password} required
                                        autoComplete="off" name="productName"
                                        onChange={(e) => {
                                            setAddUpdateField({ ...addUpdateField, password: e.target.value, });
                                        }} />
                                    <span className="form-text invalid-feedback"> *required </span>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <label className="ms-2 cursor-pointer" onClick={(e) => { props.setUserModal(false); setAddUpdateField({ ...addUpdateField, name: '', email: '', password: '' }); setEmailError(''); setEditFlag('') }} >  <u>Cancel</u>   </label>
                        {EditFlag == 1 ? (
                            <button className="SaveBtn mx-2" onClick={(e) => UpdateFUserCall()}> Update  </button>
                        ) : (
                            <button className="SaveBtn mx-2" onClick={(e) => addUserCall()}>  Add </button>
                        )}
                    </Modal.Footer>
                </Modal>
            )
            }
            <Modal show={deletePopup} size="md">
                <div className="text-center">
                    <img src="/assets/images/redInfo.svg" className="img-fluid ps-2 mt-2" />
                    <h4 className="fw-600 mt-4">    Are you sure want to delete this product?    </h4>
                    <div className="col-12 text-center py-4">
                        <button className="deleteBtn" onClick={() => DeleteProductCall()}> Yes </button>
                        <label className="ms-4" onClick={(e) => { setDeletePopup(false); }}  > <u>Cancel</u> </label>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default FranchiseUser