import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faChartBar, faFolder, faList, faListAlt, faNetworkWired, faPowerOff, faTableColumns, faUtensils } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';

function Sidemenu({ children }) {
    const loggedinemailid = 'admin@gfresh.in';
    const loggedinusername = 'G Fresh';
    const [logo, setLogo] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname.split("/");
    if (Cookies.get('flag') == 1) {
        var FinalPath = currentPath[2];
    } else {
        var FinalPath = currentPath[1];
    }

    const [isWrapperToggled, setIsWrapperToggled] = useState(false);

    function toggleWrapper() {
        if (isWrapperToggled) {
            setIsWrapperToggled(false);
            setLogo(0)
        } else {
            setIsWrapperToggled(true);
            setLogo(1);
        }
        const handleResize = () => {
            setIsWrapperToggled(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    };
    function toggleMobile() {
        if (isWrapperToggled) {
            setIsWrapperToggled(false);
        } else {
            setIsWrapperToggled(true);
        }
    };

    function getIcon(imageName) {
        switch (imageName) {
            case "faTableColumns":
                return faTableColumns;
            case "faListAlt":
                return faListAlt;
            case "faFolder":
                return faFolder;
            case "faNetworkWired":
                return faNetworkWired;
            case "faUtensils":
                return faUtensils;
            case "faList":
                return faList;
            case "faChartBar":
                return faChartBar;
            default:
                return null;
        }
    }
    var menuData;
    if (Cookies.get('flag') == 1) {
        var menuData = [
            {
                "ID": 1,
                "Name": "Dashboard",
                "Href": "dashboard",
                "Image": "faTableColumns",
                "HrefFlag": "0",
                "Submenu": []
            },
            {
                "ID": 2,
                "Name": "Manage Product",
                "Href": "ProductMaster",
                "Image": "faListAlt",
                "HrefFlag": "0",
                "Submenu": []
            },
            {
                "ID": 3,
                "Name": "Categories",
                "Href": "Category",
                "Image": "faFolder",
                "HrefFlag": "0",
                "Submenu": []
            },
            {
                "ID": 4,
                "Name": "Franchise",
                "Href": "Franchise",
                "Image": "faNetworkWired",
                "HrefFlag": "0",
                "Submenu": []
            },
        ]
    } else {
        menuData = [
            {
                "ID": 5,
                "Name": "Menu",
                "Href": "menu",
                "Image": "faUtensils",
                "HrefFlag": "0",
                "Submenu": []
            },
            {
                "ID": 6,
                "Name": "Orders",
                "Href": "orders",
                "Image": "faList",
                "HrefFlag": "0",
                "Submenu": []
            },
            {
                "ID": 7,
                "Name": "Reports",
                "Href": "reports",
                "Image": "faChartBar",
                "HrefFlag": "0",
                "Submenu": []
            },
        ]
    }

    function newtoggleMobile() {
        setIsWrapperToggled((prevValue) => !prevValue);

    }
    const [activeSubmenuItem, setActiveSubmenuItem] = useState('');
    let names = loggedinusername.split(' ');
    let firstLetter = names.map(name => name.charAt(0).toUpperCase()).join('');

    function signOut() {
        Cookies.remove('token')
        Cookies.remove('flag')
        window.location.href = "/signout"
    }
    return (
        <>
            {/* start sidebar */}
            <div className={` wrapper${isWrapperToggled ? ' toggled' : ''}`}>
                <aside className="side-menu-scroll-style sidebar-wrapper" data-simplebar="init">
                    <div className="simplebar-wrapper" style={{ margin: "0px" }}>
                        <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                            <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                                <div className="simplebar-content-wrapper" style={{ height: "100%", overflow: "hidden" }}>
                                    <div className="simplebar-content mm-active" style={{ padding: "0px" }}>
                                        <div className="sidebar-header cursor-pointer">
                                            {/* <img src={logo} id="LeftMenuLogo" className="logo-icon Leftmenulogo" alt="logo icon" onClick={toggleWrapper} /> */}
                                            {logo == 0 ?
                                                <div style={{ color: 'white', fontSize: '20px' }} onClick={toggleWrapper}>G - Fresh</div>
                                                :
                                                <div style={{ color: 'white', fontSize: '30px' }} onClick={toggleWrapper}><i className="bi bi-list"></i></div>
                                            }
                                            <div className="toggle-icon ms-auto" id="sidebar-toggle" onClick={(e) => { toggleMobile(); toggleWrapper(); }}><i className="bi bi-list"></i></div>
                                        </div>
                                        <ul className="metismenu mm-show">
                                            {menuData?.map((item, i) => {
                                                var href
                                                if (Cookies.get('flag') == 1) {
                                                    href = `/admin/${item.Href}`;
                                                } else {
                                                    href = `/${item.Href}`;
                                                }
                                                const isActive = FinalPath ? item.Href === FinalPath : i === 0;
                                                return (
                                                    <>
                                                        {item.Submenu.length == 0 ?
                                                            <li className={isActive ? 'mm-active' : ''} key={i} onClick={window.innerWidth <= 767 ? newtoggleMobile : null}>
                                                                <>
                                                                    {item.HrefFlag === 0 ?
                                                                        <a className='d-flex align-items-center' urlpath={href} href={href}>
                                                                            <FontAwesomeIcon icon={getIcon(item.Image)} className='parent-icon p-1' style={{ height: "25px", width: "25px" }} />
                                                                            <div className="menu-title">{item.Name}</div>
                                                                        </a>
                                                                        :
                                                                        <a className='d-flex align-items-center ' urlpath={href} onClick={(e) => {
                                                                            const isCtrlPressed = e.ctrlKey || e.metaKey;
                                                                            if (isCtrlPressed) {
                                                                                window.open(href, '_blank');
                                                                            } else {
                                                                                navigate(href);
                                                                            }
                                                                        }}>
                                                                            <FontAwesomeIcon icon={getIcon(item.Image)} className='parent-icon p-1' style={{ height: "25px", width: "25px" }} />
                                                                            <div className="menu-title">{item.Name}</div>
                                                                        </a>
                                                                    }
                                                                </>
                                                            </li>
                                                            :
                                                            <li>
                                                                <a className='d-flex align-items-center' onClick={(e) => setActiveSubmenuItem((prevItem) =>
                                                                    prevItem === item.Name ? '' : item.Name
                                                                )}>
                                                                    <FontAwesomeIcon icon={getIcon(item.Image)} className='parent-icon p-1' style={{ height: "25px", width: "25px" }} />
                                                                    <div className="menu-title">{item.Name}</div>
                                                                    <FontAwesomeIcon icon={faAngleDown} className={`${isWrapperToggled ? ' parent-icon p-1' : 'parent-icon p-1 ps-4'}`} style={{ height: "15px", width: "15px" }} />
                                                                </a>
                                                                {activeSubmenuItem === item.Name && (
                                                                    <div className='py-1'>
                                                                        {item.Submenu.map((subItem, j) => {
                                                                            const isSubActive = subItem.Href === FinalPath;
                                                                            return (
                                                                                <li className={isSubActive ? 'mm-active' : ''} key={j} onClick={window.innerWidth <= 767 ? newtoggleMobile : null}>
                                                                                    <a className='d-flex ps-5 align-items-center ps-3' urlpath={"/" + subItem.Href}
                                                                                        onClick={(e) => {
                                                                                            const isCtrlPressed = e.ctrlKey || e.metaKey;
                                                                                            if (isCtrlPressed) {
                                                                                                window.open(subItem.Href, '_blank');
                                                                                            } else {
                                                                                                navigate(subItem.Href);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={getIcon(subItem.Image)} className='parent-icon p-1' style={{ height: "20px", width: "20px" }} />
                                                                                        <div className="menu-title ms-1">{subItem.Name}</div>
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </li >
                                                        }
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside >
                <header className="top-header">
                    <nav className="navbar navbar-expand gap-3 pe-0">
                        <div className="mobile-toggle-icon fs-3" onClick={toggleMobile}>
                            <i className="bi bi-list"></i>
                        </div>
                        <div className="text-center" style={{ color: '#151821' }}><h5 className='mb-0'><b>{Cookies.get('flag') == 1 ? 'POS Admin' : 'POS'}</b></h5></div>
                        <div className="top-navbar-right ms-auto">
                            <ul className="navbar-nav align-items-center pe-2">
                                <li className="nav-item dropdown dropdown-user-setting">
                                    <a className="nav-link d-flex align-items-center dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">
                                        <div className="user-setting d-flex align-items-center">
                                            <div className="profile-icon">
                                                <span>{firstLetter}</span>
                                            </div>
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                <div className="d-flex align-items-center">
                                                    <div className="profile-icon">
                                                        <span>{firstLetter}</span>
                                                    </div>
                                                    <div className="ms-3">
                                                        <h5 className="mb-0 dropdown-user-name">Admin</h5>
                                                        <small className="mb-0 dropdown-user-designation text-secondary" style={{ fontSize: '12px' }}>{loggedinemailid}</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <hr className='my-1' />
                                        <div className="d-flex align-items-center ps-3 cursor-pointer" onClick={() => signOut()}>
                                            <div className=""><FontAwesomeIcon icon={faPowerOff} /></div>
                                            <div className="ms-3"><span>Sign Out</span></div>
                                        </div>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav >
                </header >
                <main className="page-content">
                    {children}
                </main>
            </div >
            {/* end sidebar */}
        </>
    )
}
export default Sidemenu