import Cookies from "js-cookie";

const BaseUrl = process.env.REACT_APP_Base_URL;
var ciphertext = Cookies.get('token');
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${ciphertext}`);

export const formatDateToMMDDYYYY = (today) => {
  var date = new Date(today);
  var yr = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0");
  var day = date.getDate().toString().padStart(2, "0");
  var newDate = month + "-" + day + "-" + yr;
  return newDate;
};
export function YYYYMMDD(date) {
  var d = new Date(date);
  var curr_year = d.getFullYear();
  var curr_month = (d.getMonth() + 1).toString().padStart(2, "0");
  var curr_date = d.getDate().toString().padStart(2, "0");
  var newDate = curr_year + "-" + curr_month + "-" + curr_date;
  return newDate;
}
export var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};

export function ShowLoder() {
  return (
    <>
      <div className="text-center">
        <img
          src="https://tablepadscdn.azureedge.net/Localimages/loder.svg"
          className="LoderImg"
          style={{ display: "block" }}
        />
      </div>
      <div id="Overlay" style={{ display: "block" }}></div>
    </>
  );
}

export function HideLoder() {
  return (
    <>
      <div id="Overlay" style={{ display: "none" }}></div>
      <img
        src="https://tablepadscdn.azureedge.net/Localimages/loder.svg"
        className="m-auto LoderImg"
        style={{ display: "none" }}
      />
    </>
  );
}

export const typewatch = (function () {
  var timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export function isValidEmail(email) {
  if (!email) {
    return false; // Email is empty
  }

  const [localPart, domainPart] = email.split("@");
  if (!domainPart || domainPart.indexOf(".") === -1) {
    return false; // Domain part is missing or does not contain a dot
  }

  const localPartRegex = /^[^@\s]+$/; // Match any character that is not '@' or whitespace
  if (!localPartRegex.test(localPart)) {
    return false; // Local part contains invalid characters or is empty
  }

  const domainPartRegex = /^[A-Za-z0-9.-]+$/; // Match letters, numbers, dots, and hyphens
  if (!domainPartRegex.test(domainPart) || domainPart.length < 2) {
    return false; // Domain part contains invalid characters or is too short
  }

  // Check that the domain part does not contain consecutive periods
  if (domainPart.indexOf("..") !== -1) {
    return false;
  }

  const tldRegex = /^[A-Za-z]{2,}$/; // Match at least two letters for the TLD
  const tld = domainPart.split(".").pop();
  if (!tldRegex.test(tld)) {
    return false; // Invalid TLD
  }
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!pattern.test(email)) {
    return false;
  }
  return true;
}

export function Camelcase(str) {
  return str.replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const CamelCaseWithSpaces = (input) => {
  if (/\s/.test(input)) {
    return input.replace(/\b\w/g, (match) => match.toUpperCase());
  }
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase());
};

export function ApiGetCall(endpoint) {
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => {
      if (response.status == 401) {
        Cookies.remove('token')
        window.location.reload();
      }
      return response.text();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export function ApiGetCallForPDF(endpoint) {
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => {
      if (response.status == 401) {
        Cookies.remove('token')
        window.location.reload();
      }
      return response;
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export function ApiDeleteCall(endpoint) {
  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => {
      if (response.status == 401) {
        Cookies.remove('token')
        window.location.reload();
      }
      return response.text();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export function ApiPostCall(endpoint, payload) {
  var urlencoded = payload;
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export function ApiPostFormDataCall(endpoint, payload) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${ciphertext}`);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: payload,
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export function ApiUpdateCall(endpoint, payload) {
  var urlencoded = payload;
  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export const isValidValue = (value) => {
  if (value === undefined || value === null || value === 0 || value === "") {
    return false;
  }
  return true;
};

export const findParent = (element) => {
  let parentElement = element.parentNode;
  for (let i = 0; i < 12; i++) {
    if (parentElement.classList.contains("parent")) {
      return parentElement;
    } else {
      parentElement = parentElement.parentNode;
    }
  }
  return null;
};

export const CheckValidation = ({ formID }) => {
  let isValid = true;
  const formElement = document.getElementById(formID);
  const inputs = formElement.querySelectorAll("select, textarea, input");

  inputs.forEach((input) => {
    if (!input.required) {
      return;
    }
    const inputParent = findParent(input);
    if (inputParent && !inputParent.classList.contains("d-none")) {
      if (input.tagName == "SELECT" && input.value === "0") {
        const invalidFeedback =
          input.parentNode.querySelector(".invalid-feedback");
        invalidFeedback.style.display = "block";
        isValid = false;
      } else if (!isValidValue(input.value)) {
        const invalidFeedback =
          input.parentNode.querySelector(".invalid-feedback");
        invalidFeedback.style.display = "block";
        isValid = false;
      } else {
        const invalidFeedback =
          input.parentNode.querySelector(".invalid-feedback");
        invalidFeedback.style.display = "";
      }
    }
  });

  return isValid;
};
export function formatDate(dateString) {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
}

export function formatTime(dateString) {
  const date = new Date(dateString);
  return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}