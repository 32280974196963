import React, { useEffect, useState } from 'react'
import { ApiGetCall, ApiPostCall, ApiUpdateCall, CheckValidation, HideLoder, ShowLoder, isValidEmail } from '../Component/Common';
import { Modal } from 'react-bootstrap';
import AlertsComp from '../Component/AlertsComp';

function Franchise() {
    const [FranchiseList, setFranchiseList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [isFranchiseModal, setIsFranchiseModal] = useState(false);
    const [addUpdateFlag, setAddUpdateFlag] = useState("");
    const [emailError, setEmailError] = useState("");
    const [addUpdateField, setAddUpdateField] = useState({
        FranchiseName: '',
        Location: '',
        ownerName: '',
        ownerNumber: '',
        ownerEmail: '',
        gstNumber: '',
        id: ''
    });
    const[FId,setFId]=useState('')
    useEffect(() => {
        GetFranchiseList();
    }, []);

    async function GetFranchiseList() {
        setLoading(true);
        ApiGetCall("/franchise").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.rows?.length != 0) {
                        setFranchiseList(responseRs?.data?.rows);
                    } else {
                        setFranchiseList([]);
                    }
                }
            }
        });
    }
    async function addFranchiseCall() {
        var isFormValid = CheckValidation({ formID: "FranchiseForm" });
        if (addUpdateField.ownerEmail == null) {
            addUpdateField.ownerEmail = "";
        }
        if (addUpdateField.ownerEmail !== "") {
            if (!isValidEmail(addUpdateField.ownerEmail)) {
                setEmailError('*Invalid Email')
                isFormValid = false;
            } else {
                setEmailError('')
            }
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            name: addUpdateField.FranchiseName,
            location: addUpdateField.Location,
            ownerName: addUpdateField.ownerName,
            ownerNumber: addUpdateField.ownerNumber,
            ownerEmail: addUpdateField.ownerEmail,
            gstNumber: addUpdateField.gstNumber
        });
        await ApiPostCall("/franchise", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(
                        <AlertsComp show={true} variant="success" msg={"Franchise Added Successfully."} />
                    );
                    setIsFranchiseModal(false);
                    setTimeout(() => {
                        GetFranchiseList();
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }
    async function updateFranchiseCall() {
        var isFormValid = CheckValidation({ formID: "FranchiseForm" });
        if (addUpdateField.ownerEmail == null) {
            addUpdateField.ownerEmail = "";
        }
        if (addUpdateField.ownerEmail !== "") {
            if (!isValidEmail(addUpdateField.ownerEmail)) {
                setEmailError('*Invalid Email')
                isFormValid = false;
            } else {
                setEmailError('')
            }
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            name: addUpdateField.FranchiseName,
            location: addUpdateField.Location,
            ownerName: addUpdateField.ownerName,
            ownerNumber: addUpdateField.ownerNumber,
            ownerEmail: addUpdateField.ownerEmail,
            gstNumber: addUpdateField.gstNumber
        });
        await ApiUpdateCall("/franchise/" + FId, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(
                        <AlertsComp show={true} variant="success" msg={"Franchise Updated Successfully."} />
                    );
                    setIsFranchiseModal(false);
                    setTimeout(() => {
                        GetFranchiseList();
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }
    async function GetElementByID(id) {
        setLoading(true);
        ApiGetCall("/franchise/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                const sugData = responseRs?.data;
                setLoading(false);
                if (responseRs.status == "success") {
                    if (sugData?.length != 0) {
                        setAddUpdateField({ ...addUpdateField, FranchiseName: sugData.name, Location: sugData.location, ownerName: sugData.ownerName, ownerNumber: sugData.ownerNumber, ownerEmail: sugData.ownerEmail, gstNumber: sugData.gstNumber })
                    }
                }
            }
        });
    }
    return (
        <main className="main-container">
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div>
                <div className="row col-12 align-items-center pb-1">
                    <div className="col-md-9">
                        <h1 className="PageHeading mb-0">Manage Franchise</h1>
                    </div>
                    <div className="col-md-3 pe-0 text-end">
                        <label className="BorderBtn text-center me-0 cursor-pointer" title="Add Building"
                            onClick={(e) => { setIsFranchiseModal(true); setAddUpdateFlag(1); setAddUpdateField({ ...addUpdateField, FranchiseName: '', Location: '', ownerName: '', ownerNumber: '', ownerEmail: '', gstNumber: '' }) }} >
                            Add Franchise
                            <img src="/assets/images/AddInventory.svg" className="img-fluid ps-2" />
                        </label>
                    </div>
                </div>
                <div className="GridBox p-3 mt-2">
                    <div className="greyBox p-3 data-table-container">
                        <div className='row row-cols-1 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 g-4 g-lg-4'>
                            {FranchiseList.length !== 0 ? (
                                FranchiseList.map((item, i) => {
                                    return (
                                        <div className='col position-relative'>
                                            <div className='px-4 pb-4 pt-2 GridBox boxshaowstyle cursor-pointer' style={{ maxHeight: '100%', minHeight: '100%', backgroundColor: '#dcdcdc' }}>
                                                <div className='col-12 text-end'><img src="/assets/images/EditIconNew.svg" title="Edit" className="img-fluid px-1 cursor-pointer" onClick={(e) => { setFId(item.id); setIsFranchiseModal(true); setAddUpdateFlag(2); GetElementByID(item.id) }} ></img></div>
                                                <div onClick={(e) => window.location.href = `/admin/Manage-Franchise?id=${item.id}`}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className='mb-0 fw-600 planname-style' style={{ wordBreak: 'break-all', overflowWrap: 'break-word', maxWidth: '300px' }}>
                                                            {item.name}
                                                        </h4>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className='mt-3 content-forsetupcards'><span className="fw-600">Location :</span> {item.location}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <div className='m-auto py-5'>
                                    <div className="col-md-12 text-center h3">No Record Found</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isFranchiseModal} size="lg">
                <Modal.Header closeButton onClick={(e) => setIsFranchiseModal(false)}>
                    <Modal.Title>
                        {addUpdateFlag == 1 ? "Add Franchise" : "Edit Franchise"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="FranchiseForm" className="parent">
                        <div className="row">
                            <div className="col-md-6 py-2">
                                <div className="fw-600 mb-1">Franchise Name</div>
                                <input className="form-control" value={addUpdateField.FranchiseName} required
                                    autoComplete="off" name="productName"
                                    onChange={(e) => {
                                        setAddUpdateField({ ...addUpdateField, FranchiseName: e.target.value, });
                                    }} />
                                <span className="form-text invalid-feedback"> *required </span>
                            </div>
                            <div className="col-md-6 py-2">
                                <div className="fw-600 mb-1">Location </div>
                                <input type='text' className="form-control" value={addUpdateField.Location} required
                                    autoComplete="off" name="productName"
                                    onChange={(e) => {
                                        setAddUpdateField({ ...addUpdateField, Location: e.target.value, });
                                    }} />
                                <span className="form-text invalid-feedback"> *required </span>
                            </div>
                            <div className="col-md-6 py-2">
                                <div className="fw-600 mb-1">Owner Name </div>
                                <input type='text' className="form-control" value={addUpdateField.ownerName} required
                                    autoComplete="off" name="productName"
                                    onChange={(e) => {
                                        setAddUpdateField({ ...addUpdateField, ownerName: e.target.value, });
                                    }} />
                                <span className="form-text invalid-feedback"> *required </span>
                            </div>
                            <div className="col-md-6 py-2">
                                <div className="fw-600 mb-1">Owner Number</div>
                                <input type='number' className="form-control" value={addUpdateField.ownerNumber} required
                                    autoComplete="off" name="productName"
                                    onChange={(e) => {
                                        setAddUpdateField({ ...addUpdateField, ownerNumber: e.target.value, });
                                    }} />
                                <span className="form-text invalid-feedback"> *required </span>
                            </div>
                            <div className="col-md-6 py-2">
                                <div className="fw-600 mb-1">owner Email</div>
                                <input type='text' className="form-control" value={addUpdateField.ownerEmail}
                                    autoComplete="off" name="productName"
                                    onChange={(e) => {
                                        setAddUpdateField({ ...addUpdateField, ownerEmail: e.target.value, });
                                    }} />
                                <span className="Emailinvalid">
                                    {emailError}
                                </span>
                            </div>
                            <div className="col-md-6 py-2">
                                <div className="fw-600 mb-1">GST Number</div>
                                <input type='text' className="form-control" value={addUpdateField.gstNumber} required
                                    autoComplete="off" name="productName"
                                    onChange={(e) => {
                                        setAddUpdateField({ ...addUpdateField, gstNumber: e.target.value, });
                                    }} />
                                <span className="form-text invalid-feedback"> *required </span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <label className="ms-2 cursor-pointer" onClick={(e) => setIsFranchiseModal(false)}>
                        <u>Cancel</u>
                    </label>
                    {addUpdateFlag == 1 ? (
                        <button className="SaveBtn mx-2" onClick={(e) => addFranchiseCall()}> Add </button>
                    ) : (
                        <button className="SaveBtn mx-2" onClick={(e) => updateFranchiseCall()}> Update</button>
                    )}
                </Modal.Footer>
            </Modal>
        </main>
    )
}

export default Franchise