import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApiGetCall, HideLoder, ShowLoder, } from '../Component/Common';

function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [GetDataList, setGetDataList] = useState({});

    useEffect(() => {
        GetReportsData()
    }, [])

    async function GetReportsData() {
        setLoading(true);
        ApiGetCall(`/report/admin/dashboard`).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.length != 0) {
                        setGetDataList(responseRs?.data);
                    } else {
                        setGetDataList({});
                    }
                }
            }
        });
    }

    var chartData1 = [{
        name: "Revenue",
        data: GetDataList?.RevenueByMonth?.map(({ MonthlyRevenue }) => MonthlyRevenue)
    }];
    var chartOptions1 = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Revenue by Month',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: GetDataList?.RevenueByMonth?.map(({ Month }) => Month),
        }
    }

    var series = [{
        data: GetDataList?.FranchiseShare?.map(({ TotalRevenue }) => TotalRevenue)
    }]

    var options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: GetDataList?.FranchiseShare?.map(({ FranchiseName }) => FranchiseName),
        }
    }

    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="row col-12 align-items-center pb-1">
                <div className="col-md-9">
                    <h1 className="PageHeading mb-0">Dashboard</h1>
                </div>
            </div>
            <div className='col-md-12 d-flex flex-wrap'>
                <div className='col-md-3 col-sm-6 px-2 mb-3'>
                    <div className='GridBox text-center p-3'>
                        <div className='font-16'>Today's Sale</div>
                        <div className='fw-600 font-20'>{GetDataList?.SaleOverview?.TodaySale}</div>
                    </div>
                </div>
                <div className='col-md-3 col-sm-6 px-2 mb-3'>
                    <div className='GridBox text-center p-3'>
                        <div className='font-16'>This Week Sale</div>
                        <div className='fw-600 font-20'>{GetDataList?.SaleOverview?.ThisWeekSale}</div>
                    </div>
                </div>
                <div className='col-md-3 col-sm-6 px-2 mb-3'>
                    <div className='GridBox text-center p-3'>
                        <div className='font-16'>Last Month Sale</div>
                        <div className='fw-600 font-20'>{GetDataList?.SaleOverview?.LastMonthSale}</div>
                    </div>
                </div>
                <div className='col-md-3 col-sm-6 px-2 mb-3'>
                    <div className='GridBox text-center p-3'>
                        <div className='font-16'>This Year Sale</div>
                        <div className='fw-600 font-20'>{GetDataList?.SaleOverview?.ThisYearSale}</div>
                    </div>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-md-12 py-3'>
                    <div className='GridBox h-100 p-3 py-5'>
                        <div id="chart">
                            <ReactApexChart options={chartOptions1} series={chartData1} type="line" height={350} />
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='GridBox h-100 p-3 py-5'>
                        <div id="chart" className='py-5'>
                            <ReactApexChart options={options} series={series} type="bar" />
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
            <div className='GridBox h-100 p-3 py-5'>
                <h4 className="mb-4">Hero Products</h4>
                <div className="product-list">
                    {GetDataList?.TopFiveProduct?.map((product, index) => (
                        <div className="product-item" key={index}>
                            <h5>{product.ProductName}</h5>
                            <p>{product.CategoryName}</p>
                            {/* Add additional information or icons here */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
            </div>
        </div>
    )
}

export default Dashboard