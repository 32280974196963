import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function ErrorPage() {
  return (
    <div className="container">
    <div className="row">
        <div className="col-md-7 offset-md-2">
            <div className="text-center pt-5" style={{ color: '#495057' }}>
                <h3>SORRY, PAGE NOT FOUND</h3>
            </div>
            <div className="text-center pt-1" style={{ color: '#878a99' }}>
                <h6>The page you are looking for is not available!</h6>
            </div>
            <div className="text-center pt-3">
                <button className="SaveBtnUser mx-2" onClick={() => window.location.href = '/'}>
                    <FontAwesomeIcon icon={faHome} className="pe-2" /> Back to home
                </button>
            </div>
        </div>
    </div>
</div>
  )
}

export default ErrorPage