import { faAngleRight, faCogs, faEdit, faMinus, faPlus, faRedo, faRedoAlt, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiGetCall, ApiPostCall, ApiUpdateCall, HideLoder, ShowLoder, getUrlParameter } from '../Component/Common';
import AlertsComp from '../Component/AlertsComp';

function Menu() {
    const navigate = useNavigate();
    const [selectedTable, setSelectedTable] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState(0);
    const [visibleID, setVisibleID] = useState(null);
    const [Categories, setCategories] = useState('')
    const [items, setItems] = useState([])
    const [cartItems, setCartItems] = useState([]);
    const [SearchMenu, setSearchMenu] = useState('')
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const IDForEdit = getUrlParameter('id')
    const location = useLocation();
    const SelectedEditData = location.state && location.state.item;
    useEffect(() => {
        GetCategoryList();
        GetMenuAccordingId(0, null);
        if (IDForEdit != false) {
            setCartItems(SelectedEditData)
        }
    }, []);

    async function GetCategoryList() {
        ApiGetCall("/category/franchisewise").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.length != 0) {
                        setCategories(responseRs?.data);
                    } else {
                        setCategories([]);
                    }
                }
            }
        });
    }
    async function GetMenuAccordingId(id, searchString) {
        var Sstring;
        if (searchString == null) {
            Sstring = ''
        } else {
            Sstring = searchString
        }
        var url;
        if (id == 0) {
            url = '/franchise-product?searchQuery=' + Sstring
        } else {
            url = '/franchise-product?CategoryId=' + id + '&searchQuery=' + Sstring;
        }
        setLoading(true)
        ApiGetCall(url).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.rows?.length != 0) {
                        setItems(responseRs?.data?.rows);
                    } else {
                        setItems([]);
                    }
                }
            }
        });
    }

    const handleAddItem = (index) => {
        const updatedItems = [...items];
        if (!updatedItems[index]?.hasOwnProperty('count')) {
            updatedItems[index].count = 0;
        }
        updatedItems[index].count++;
        updateCart(updatedItems[index].id, getQuantityByProductId(updatedItems[index].id) + 1, updatedItems[index])
        if (typeof updatedItems[index].price === 'number') {
            updatedItems[index].amount = updatedItems[index].count * updatedItems[index].price;
            setItems(updatedItems);
        }
    };

    const handleMinusItem = (index) => {
        const updatedItems = [...items];
        if (updatedItems[index].count > 0) {
            updatedItems[index].count--;
            updatedItems[index].amount = updatedItems[index].count * updatedItems[index].price;
        }
        updateCart(updatedItems[index].id, getQuantityByProductId(updatedItems[index].id) - 1, updatedItems[index])
        setItems(updatedItems);
    };

    let totalCount = 0;
    items.forEach(item => { totalCount += item.count });
    const handleSelectAllItems = () => {
        var allSelectedItems = cartItems
        navigate('/order-list', { state: { allSelectedItems } })
    };
    function ShowHideDiv(itemId) {
        setVisibleID(prevID => (prevID == itemId ? null : itemId));
    }
    const handleKgChange = (event, pricePerKg, index) => {
        const enteredKg = Number(event.target.value);
        const newItems = [...items];
        newItems[index].kg = Number(enteredKg.toFixed(3));
        newItems[index].count = enteredKg;
        newItems[index].amount = Number((enteredKg * pricePerKg).toFixed(2));
        if (enteredKg === '0') {
            newItems[index].kg = '';
        }
        updateCart(newItems[index].id, Number(enteredKg.toFixed(3)), newItems[index])
        setItems(newItems);
    };

    const handleAmountChange = (event, pricePerKg, index) => {
        const enteredAmount = Number(event.target.value);
        const newItems = [...items];
        newItems[index].kg = Number((enteredAmount / pricePerKg).toFixed(3));
        newItems[index].count = enteredAmount / pricePerKg;
        newItems[index].amount = enteredAmount;
        if (enteredAmount === '0') {
            newItems[index].amount = '';
        }
        updateCart(newItems[index].id, Number((enteredAmount / pricePerKg).toFixed(3)), newItems[index])
        setItems(newItems);
    };


    const updateCart = (productId, latestQuantity, item) => {
        // Find the index of the product in the cart
        const productIndex = cartItems?.findIndex(item => item.FranchiseProductId === productId);

        // If the product is not in the cart and the latest quantity is not zero, add it to the cart
        if (productIndex === -1 && latestQuantity > 0) {
            setCartItems([...cartItems, { FranchiseProductId: productId, quantity: latestQuantity, ...item }]);
            return;
        }

        // If the product is already in the cart and the latest quantity is zero, remove it from the cart
        if (productIndex !== -1 && latestQuantity === 0) {
            const updatedCartItems = cartItems?.filter(item => item.FranchiseProductId !== productId);
            setCartItems(updatedCartItems);
            return;
        }

        // If the product is already in the cart and the latest quantity is greater than zero, update its quantity
        if (productIndex !== -1 && latestQuantity > 0) {
            const updatedCartItems = cartItems?.map(item => {
                if (item.FranchiseProductId === productId) {
                    return { ...item, quantity: latestQuantity };
                }
                return item;
            });
            setCartItems(updatedCartItems);
        }
    };


    const getQuantityByProductId = (productId) => {
        const product = cartItems?.find(item => item.FranchiseProductId == productId);
        return product ? Number(product.quantity.toFixed(3)) : 0;
    };

    const getAmountByProductId = (productId, price) => {
        const product = cartItems?.find(item => item.FranchiseProductId == productId);
        return product ? Number((product.quantity * price).toFixed(0)) : 0;
    };

    async function handleUpdateMenu() {
        var vArray = [];
        {
            cartItems?.map((item, i) => {
                var vjson = {};
                vjson['FranchiseProductId'] = item.FranchiseProductId;
                vjson['quantity'] = item.quantity;
                vArray.push(vjson)
            })
        }
        setLoading(true);
        var raw = JSON.stringify({
            items: vArray
        });
        await ApiUpdateCall("/invoice/" + IDForEdit, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Order Updated Successfully."} />);
                    setTimeout(() => {
                        window.location = '/orders'
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }
    return (
        <div className='p-0'>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="row align-items-center pb-1">
                <div className="col-md-9">
                    <h1 className="PageHeading mb-0">{IDForEdit != '' ? 'Edit Order' : 'Create Order'}</h1>
                </div>
            </div>
            <div>
                <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                    <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                    <input className="form-control marginCss" autoComplete="off" type="text" placeholder="Search Menu"
                        value={SearchMenu} onChange={(e) => { setSearchMenu(e.target.value) }}
                        onKeyUp={(e) => GetMenuAccordingId(selectedCategories, e.target.value)} onMouseUp={(e) => GetMenuAccordingId(selectedCategories, e.target.value)} />
                </form>
            </div>
            <div className='py-2'>
                <div className="horizontal-scroll-container mt-2">
                    {Categories.length !== 0 ? (
                        <div className="d-flex">
                            <div className={`GridBoxForTabel py-2 px-3 mx-1 ${selectedCategories === 0 ? 'greenColor' : 'whiteColor'
                                }`} onClick={() => { setSelectedCategories(0); GetMenuAccordingId(0, null); setSearchMenu('') }}
                            >All</div>
                            {Categories?.map((item, i) => {
                                return (
                                    <div key={item.id} className={`GridBoxForTabel py-2 px-3 mx-1 ${selectedCategories === item.id ? 'greenColor' : 'whiteColor'
                                        }`} onClick={() => { setSelectedCategories(item.id); GetMenuAccordingId(item.id, null); setSearchMenu('') }}
                                    >{item.name}</div>
                                );
                            })}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className='py-2 row'>
                    {items.length != 0 ?
                        items?.map((item, index) => {
                            const isDivVisible = item.id === visibleID;
                            return (
                                <div className='col-xl-4 col-md-4 col-lg-2 col-12 py-2'>
                                    <div key={index} className='GridBox h-100 py-2 px-3'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div><div className='fw-600 font-16'>{item.ProductMaster.name}</div>
                                                <div className='menu-price-style'>Price: {item.price.toFixed(2) + ' (' + item.ProductMaster.unit + ')'}</div>
                                            </div>
                                            {item.ProductMaster.unit == 'Pcs' ?
                                                <div className="counter-container">
                                                    <div className="counter-button" onClick={() => handleMinusItem(index)}>
                                                        <FontAwesomeIcon icon={faMinus} />
                                                    </div>
                                                    <div>{getQuantityByProductId(item.id)}</div>
                                                    <div className="counter-button" onClick={() => handleAddItem(index)}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </div>
                                                </div>
                                                :
                                                <div className='pe-2'>
                                                    <img src="/assets/images/DownRoundArrow.svg" title='Show' className={`cursor-pointer img-fluid ${isDivVisible ? "d-none" : ""}`} onClick={() => { ShowHideDiv(item.id) }} style={{ height: '25px' }} />
                                                    <img src="/assets/images/UpRoundArrow.svg" title='Hide' className={`img-fluid cursor-pointer ${isDivVisible ? "" : "d-none"}`} onClick={() => { ShowHideDiv(item.id) }} style={{ height: '25px' }} />
                                                </div>
                                            }
                                        </div>
                                        {isDivVisible && (
                                            <div className='py-2'>
                                                <div className='d-flex'>
                                                    <div className='px-1'>
                                                        <label htmlFor="kg">Kg:</label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            id="kg"
                                                            value={getQuantityByProductId(item.id)}
                                                            onChange={(event) => handleKgChange(event, item.price, index)}
                                                        />
                                                    </div>
                                                    <div className='px-1'>
                                                        <label htmlFor="amount">Amount (Rs):</label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            id="amount"
                                                            value={getAmountByProductId(item.id, item.price)}
                                                            onChange={(event) => handleAmountChange(event, item.price, index)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className='pt-2'>
                                                    <label className='fw-600'>Calculated values:</label><br />
                                                    <label>Kg: {getQuantityByProductId(item.id)}</label><br />
                                                    <label>Amount (Rs): {getAmountByProductId(item.id, item.price)}</label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className='col-12 text-center py-3'>No Record Found</div>
                    }
                </div>
            </div>
            {IDForEdit != '' ?
                <div style={{ position: 'relative' }} onClick={(e) => handleUpdateMenu(e)}>
                    <div style={{ position: 'fixed', bottom: '20px', right: '20px' }} className='greenColor GridBoxForTabel py-2 px-3'>
                        Update <FontAwesomeIcon icon={faSyncAlt} />
                    </div>
                </div>
                :
                <div style={{ position: 'relative' }} onClick={(e) => handleSelectAllItems(e)}>
                    <div style={{ position: 'fixed', bottom: '20px', right: '20px' }} className='greenColor GridBoxForTabel py-2 px-3'>
                        Proceed<FontAwesomeIcon icon={faAngleRight} />
                    </div>
                </div>
            }
        </div>
    )
}

export default Menu
