import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
    ApiDeleteCall,
    ApiGetCall,
    ApiPostCall,
    ApiUpdateCall,
    CheckValidation,
    HideLoder,
    ShowLoder,
} from "../Component/Common";
import AlertsComp from "../Component/AlertsComp";

function ManageCategory() {
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [isCategoryModal, setIsCategoryModal] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [addUpdateFlag, setAddUpdateFlag] = useState("");
    const [productList, setProductList] = useState([]);
    const [addUpdateField, setAddUpdateField] = useState({
        categoryName: ''
    });
    const [Categoryid, setCategoryid] = useState('')
    useEffect(() => {
        GetCategoryList();
    }, []);

    async function GetCategoryList() {
        setLoading(true);
        ApiGetCall("/category").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.rows?.length != 0) {
                        setProductList(responseRs?.data?.rows);
                    } else {
                        setProductList([]);
                    }
                }
            }
        });
    }

    async function GetElementByID(id) {
        setLoading(true);
        ApiGetCall("/category/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                const sugData = responseRs?.data;
                setLoading(false);
                if (responseRs.status == "success") {
                    if (sugData?.length != 0) {
                        setAddUpdateField({ ...addUpdateField, categoryName: sugData?.name });
                    } else {
                        setProductList([]);
                    }
                }
            }
        });
    }

    async function addCategoryCall(flag) {
        var isFormValid = CheckValidation({ formID: "CategoryForm" });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            name: addUpdateField.categoryName,
        });
        await ApiPostCall("/category", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Category Added Successfully."} />);
                    setIsCategoryModal(false);
                    setTimeout(() => {
                        GetCategoryList();
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }

    async function UpdateProductCall() {
        var isFormValid = CheckValidation({ formID: "CategoryForm" });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            name: addUpdateField.categoryName,
        });
        await ApiUpdateCall("/category/" + Categoryid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Category Updated Successfully."} />);
                    setIsCategoryModal(false);
                    setTimeout(() => {
                        GetCategoryList()
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });

    }
    async function DeleteProductCall() {
        setLoading(true);
        await ApiDeleteCall("/category/" + Categoryid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Product Deleted Successfully."} />);
                    setDeletePopup(false);
                    setTimeout(() => {
                        GetCategoryList()
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });

    }

    return (
        <main className="main-container">
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div>
                <div className="row col-12 align-items-center pb-1">
                    <div className="col-md-9">
                        <h1 className="PageHeading mb-0">Manage Category</h1>
                    </div>
                    <div className="col-md-3 pe-0 text-end">
                        <label className="BorderBtn text-center me-0 cursor-pointer" title="Add Building"
                            onClick={(e) => { setIsCategoryModal(true); setAddUpdateFlag(1); setAddUpdateField({ ...addUpdateField, categoryName: '' }); }}  >
                            Add Category
                            <img src="/assets/images/AddInventory.svg" className="img-fluid ps-2" />
                        </label>
                    </div>
                </div>
                <div className="GridBox p-3">
                    <div className="greyBox p-3 data-table-container">
                        <table className="table data-table">
                            <thead className="GridHeader">
                                <tr>
                                    <th scope="col" className="fw-600"> Category Name</th>
                                    <th scope="col" className="fw-600 text-center"> Action  </th>
                                </tr>
                            </thead>
                            <tbody>
                                {productList.length != 0 ? (
                                    productList.map((item, i) => {
                                        return (
                                            <tr key={i} className="OddEvenClass tableBorderHide">
                                                <td scope="col">{item.name}</td>
                                                <td scope="col" className="text-center py-12"  >
                                                    <img src="/assets/images/EditIconNew.svg" title="Edit Product" className="img-fluid px-1 cursor-pointer"
                                                        onClick={(e) => { setCategoryid(item.id); setIsCategoryModal(true); setAddUpdateFlag(2); GetElementByID(item.id); }} />
                                                    <img src="/assets/images/DeleteIcon.svg" title="Delete Product" className="img-fluid px-1 cursor-pointer"
                                                        onClick={(e) => { setCategoryid(item.id); setDeletePopup(true); }} />
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr className="text-center">  <td colSpan={15}>No Record Found</td>  </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Modal show={isCategoryModal} size="sm">
                    <Modal.Header closeButton onClick={(e) => setIsCategoryModal(false)}>
                        <Modal.Title>
                            {addUpdateFlag == 1 ? "Add Category" : "Edit Category"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div id="CategoryForm" className="parent">
                            <div className="row">
                                <div className="col-md-12 py-2">
                                    <div className="fw-600 mb-1">Category Name</div>
                                    <input type="text" className="form-control" value={addUpdateField.categoryName}
                                        required autoComplete="off" name="productName"
                                        onChange={(e) => { setAddUpdateField({ ...addUpdateField, categoryName: e.target.value, }); }} />
                                    <span className="form-text invalid-feedback"> *required{" "} </span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <label className="ms-2 cursor-pointer" onClick={(e) => setIsCategoryModal(false)}  >  <u>Cancel</u>   </label>
                        {addUpdateFlag == 1 ? (
                            <button className="SaveBtn mx-2" onClick={(e) => addCategoryCall()}   >  Add </button>
                        ) : (
                            <button className="SaveBtn mx-2" onClick={(e) => UpdateProductCall()}  > Update  </button>
                        )}
                    </Modal.Footer>
                </Modal>
                <Modal show={deletePopup} size="md">
                    <div className="text-center">
                        <img src="/assets/images/redInfo.svg" className="img-fluid ps-2 mt-2" />
                        <h4 className="fw-600 mt-4">    Are you sure want to delete this category?    </h4>
                        <div className="col-12 text-center py-4">
                            <button className="deleteBtn" onClick={() => DeleteProductCall()}> Yes </button>
                            <label className="ms-4" onClick={(e) => { setDeletePopup(false); }}  > <u>Cancel</u> </label>
                        </div>
                    </div>
                </Modal>
            </div>
        </main>
    );
}

export default ManageCategory;
