import React, { useState } from 'react'
import Cookies from 'js-cookie';
import { ApiPostCall, CheckValidation, HideLoder, ShowLoder, isValidEmail } from '../Component/Common';
import AlertsComp from '../Component/AlertsComp';

function AdminLogin() {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const pathItem = pathArray[1];
    const [email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [emailError, setEmailError] = useState("");

    async function LoginCall() {
        var isFormValid = CheckValidation({ formID: "UserForm" });
        if (email == null) {
            email = "";
        }
        if (email !== "") {
            if (!isValidEmail(email)) {
                setEmailError('*Invalid Email')
                isFormValid = false;
            } else {
                setEmailError('')
            }
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            email: email,
            password: Password,
        });
        var url;
        if (pathItem == 'admin') {
            url = '/auth/admin'
        } else {
            url = '/auth/franchise'
        }
        await ApiPostCall(url, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    const expires = 1;
                    Cookies.set('token', responseRs.token, { expires });
                    if (pathItem == 'admin') {
                        Cookies.set('flag', 1, { expires });
                    } else {
                        Cookies.set('flag', 2, { expires });
                    }
                    setAlerts(<AlertsComp show={true} variant="success" msg={"User Login Successfully."} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        if (pathItem == 'admin') {
                            window.location.href = '/admin/dashboard';
                        } else {
                            window.location.href = '/menu';
                        }
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 3000);
                }
            }
        });
    }

    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="position-relative MainDiv">
                <div className="card mx-auto">
                    <div id="UserForm" className="p-4 row parent">
                        <div className="col-12 text-center mt-3">
                            <label className='fw-600 py-2'>Email:</label>
                            <input type='text' className="form-control" value={email}
                                autoComplete="off" name="email" onChange={(e) => setEmail(e.target.value)} />
                            <span className="Emailinvalid">
                                {emailError}
                            </span>
                        </div>
                        <div className="col-12 text-center py-3">
                            <label className='fw-600 py-2'>Password:</label>
                            <input type='password' className="form-control" value={Password} required
                                autoComplete="off" name="password" onChange={(e) => setPassword(e.target.value)} />
                            <span className="form-text invalid-feedback"> *required </span> </div>
                        <div className="col-md-8 offset-md-2 text-center">
                        </div>
                        <div className="row text-center py-2">
                            <button className="SaveBtn mx-2" onClick={(e) => LoginCall()}>  Login </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminLogin