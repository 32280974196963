import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  ApiDeleteCall,
  ApiGetCall,
  ApiPostCall,
  ApiUpdateCall,
  CheckValidation,
  HideLoder,
  ShowLoder,
} from "../Component/Common";
import AlertsComp from "../Component/AlertsComp";
import { FaSort } from "react-icons/fa";
function ProductMaster() {
  const [loading, setLoading] = useState(false);
  const [Alerts, setAlerts] = useState("");
  const [isproductModal, setIsproductModal] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [addUpdateFlag, setAddUpdateFlag] = useState("");
  const [productList, setProductList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [addUpdateField, setAddUpdateField] = useState({
    categorySelect: "",
    productName: "",
    productUnit: "Kg",
    unitPrice: 0,
    Gst: 0,
  });
  const [productid, setProductid] = useState('')
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    GetProductList();
    GetCategoryData();
  }, []);

  async function GetProductList() {
    setLoading(true);
    ApiGetCall("/product-master").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        setLoading(false);
        if (responseRs.status == "success") {
          if (responseRs?.data?.rows?.length != 0) {
            setProductList(responseRs?.data?.rows);
          } else {
            setProductList([]);
          }
        }
      }
    });
  }
  async function GetElementByID(id) {
    setLoading(true);
    ApiGetCall("/product-master/" + id).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        const sugData = responseRs?.data;
        setLoading(false);
        if (responseRs.status == "success") {
          if (responseRs?.data?.rows?.length != 0) {
            setAddUpdateField({
              ...addUpdateField, categorySelect: sugData.CategoryId, productName: sugData.name, productUnit: sugData.unit,
              unitPrice: sugData.price, Gst: sugData.gstPercentage,
            });
          }
        }
      }
    });
  }
  async function GetCategoryData() {
    setLoading(true);
    ApiGetCall("/category").then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        setLoading(false);
        if (responseRs.status == "success") {
          if (responseRs?.data?.rows?.length != 0) {
            setCategoryList(responseRs?.data?.rows);
          } else {
            setCategoryList([]);
          }
        }
      }
    });
  }

  async function addProductCall(flag) {
    var isFormValid = CheckValidation({ formID: "ProductForm" });
    if (!isFormValid) return;
    setLoading(true);
    var raw = JSON.stringify({
      name: addUpdateField.productName,
      unit: addUpdateField.productUnit,
      price: addUpdateField.unitPrice,
      gstPercentage: addUpdateField.Gst,
      CategoryId: addUpdateField.categorySelect,
    });
    await ApiPostCall("/product-master", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        setLoading(false);
        const responseRs = JSON.parse(result);
        if (responseRs.status == "success") {
          setAlerts(<AlertsComp show={true} variant="success" msg={"Product Added Successfully."} />);
          setIsproductModal(false);
          setTimeout(() => {
            GetProductList();
            setAlerts(<AlertsComp show={false} />);
          }, 1500);
        } else {
          setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
          setTimeout(() => {
            setAlerts(<AlertsComp show={false} />);
          }, 1500);
        }
      }
    });
  }

  async function UpdateProductCall() {
    var isFormValid = CheckValidation({ formID: "ProductForm" });
    if (!isFormValid) return;
    setLoading(true);
    var raw = JSON.stringify({
      name: addUpdateField.productName,
      unit: addUpdateField.productUnit,
      price: addUpdateField.unitPrice,
      gstPercentage: addUpdateField.Gst,
      CategoryId: addUpdateField.categorySelect,
    });
    await ApiUpdateCall("/product-master/" + productid, raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        setLoading(false);
        const responseRs = JSON.parse(result);
        if (responseRs.status == "success") {
          setAlerts(<AlertsComp show={true} variant="success" msg={"Product Updated Successfully."} />);
          setIsproductModal(false);
          setTimeout(() => {
            GetProductList()
            setAlerts(<AlertsComp show={false} />);
          }, 1500);
        } else {
          setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
          setTimeout(() => {
            setAlerts(<AlertsComp show={false} />);
          }, 1500);
        }
      }
    });

  }
  async function DeleteProductCall() {
    setLoading(true);
    await ApiDeleteCall("/product-master/" + productid).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        setLoading(false);
        const responseRs = JSON.parse(result);
        if (responseRs.status == "success") {
          setAlerts(<AlertsComp show={true} variant="success" msg={"Product Deleted Successfully."} />);
          setDeletePopup(false);
          setTimeout(() => {
            GetProductList()
            setAlerts(<AlertsComp show={false} />);
          }, 1500);
        } else {
          setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
          setTimeout(() => {
            setAlerts(<AlertsComp show={false} />);
          }, 1500);
        }
      }
    });

  }

  function handleSort(column) {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  }

  function sortAndFilterProductList() {
    let sortedList = [...productList];
  
    if (searchQuery) {
      sortedList = sortedList.filter(product => {
        const productName = product.name.toLowerCase();
        const category = product.Category.name.toLowerCase();
        const price = product.price.toString().toLowerCase();
        const unit = product.unit.toLowerCase();
        const gstPercentage = product.gstPercentage.toString().toLowerCase();
  
        return (
          productName.includes(searchQuery.toLowerCase()) ||
          category.includes(searchQuery.toLowerCase()) ||
          price.includes(searchQuery.toLowerCase()) ||
          unit.includes(searchQuery.toLowerCase()) ||
          gstPercentage.includes(searchQuery.toLowerCase())
        );
      });
    }
  
    if (sortColumn) {
      sortedList.sort((a, b) => {
        let aValue, bValue;
  
        // Adjust the comparison based on the column
        if (sortColumn === 'category') {
          aValue = a.Category.name.toLowerCase();
          bValue = b.Category.name.toLowerCase();
        } else {
          aValue = a[sortColumn].toString().toLowerCase();
          bValue = b[sortColumn].toString().toLowerCase();
        }
  
        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    }
  
    return sortedList;
  }
  

  return (
    <div>
      {Alerts}
      {loading ? <ShowLoder /> : <HideLoder />}
      <div>
        <div className="row col-12 align-items-center">
          <div className="col-md-6">
            <h1 className="PageHeading mb-0">Manage Product</h1>
          </div>
          <div className="col-md-3 py-1 text-end">
            <label className="BorderBtn text-center me-0 cursor-pointer" title="Add Building"
              onClick={(e) => { setIsproductModal(true); setAddUpdateFlag(1); setAddUpdateField({ ...addUpdateField, categorySelect: 0, productName: '', productUnit: 'Kg', unitPrice: 0, Gst: 0 }); }}  >
              Add Product
              <img src="/assets/images/AddInventory.svg" className="img-fluid ps-2" />
            </label>
          </div>
          <div className="col-md-3 pe-0 py-1 text-end">
            <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
              <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
              <input className="form-control marginCss" autoComplete="off" type="text" placeholder="Search" value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} />
            </form>
          </div>
        </div>
        <div className="GridBox p-3">
          <div className="greyBox p-3 data-table-container">
            <table className="table data-table">
              <thead className="GridHeader">
                <tr>
                  <th scope="col" className="fw-600 cursor-pointer" onClick={() => handleSort('name')}> Product Name  <FaSort /></th>
                  <th scope="col" className="fw-600 cursor-pointer" onClick={() => handleSort('category')} > Category <FaSort /></th>
                  <th scope="col" className="fw-600 cursor-pointer" onClick={() => handleSort('price')}> Price <FaSort /></th>
                  {/* <th scope="col" className="fw-600"> Unit </th> */}
                  <th scope="col" className="fw-600 cursor-pointer" onClick={() => handleSort('gstPercentage')}>  GST  <FaSort /></th>
                  <th scope="col" className="fw-600 text-center"> Action  </th>
                </tr>
              </thead>
              <tbody>
                {sortAndFilterProductList().length != 0 ? (
                  sortAndFilterProductList().map((item, i) => {
                    return (
                      <tr key={i} className="OddEvenClass tableBorderHide">
                        <td scope="col">{item.name}</td>
                        <td scope="col">{item.Category.name}</td>
                        <td scope="col">{item.price}/{item.unit}</td>
                        {/* <td scope="col">{item.unit}</td> */}
                        <td scope="col">{item.gstPercentage}</td>
                        <td scope="col" className="text-center py-12"  >
                          <img src="/assets/images/EditIconNew.svg" title="Edit Product" className="img-fluid px-1 cursor-pointer"
                            onClick={(e) => { setProductid(item.id); setIsproductModal(true); setAddUpdateFlag(2); GetElementByID(item.id); }} />
                          &nbsp;&nbsp;
                          <img src="/assets/images/DeleteIcon.svg" title="Delete Product" className="img-fluid px-1 cursor-pointer"
                            onClick={(e) => { setProductid(item.id); setDeletePopup(true); }} />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">  <td colSpan={15}>No Record Found</td>  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Modal show={isproductModal} size="lg">
          <Modal.Header closeButton onClick={(e) => setIsproductModal(false)}>
            <Modal.Title>
              {addUpdateFlag == 1 ? "Add product" : "Edit Product"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="ProductForm" className="parent">
              <div className="row">
                <div className="col-md-6 py-2">
                  <div className="fw-600 mb-1">Category Select</div>
                  <select required value={addUpdateField.categorySelect} name="Category"
                    onChange={(e) => { setAddUpdateField({ ...addUpdateField, categorySelect: e.target.value, }); }}  >
                    <option value="0">Select</option>
                    {CategoryList.map((item, j) => (
                      <option value={item.id} key={j}>   {item.name} </option>
                    ))}
                  </select>
                  <span className="form-text invalid-feedback">  *required  </span>
                </div>
                <div className="col-md-6 py-2">
                  <div className="fw-600 mb-1">Product Name</div>
                  <input type="text" className="form-control" value={addUpdateField.productName}
                    required autoComplete="off" name="productName"
                    onChange={(e) => { setAddUpdateField({ ...addUpdateField, productName: e.target.value, }); }} />
                  <span className="form-text invalid-feedback"> *required{" "} </span>
                </div>

                <div className="col-md-6 py-2">
                  <div className="fw-600 mb-1">Unit Price</div>
                  <div className=" pe-0">
                    <div className="input-group">
                      <span className="input-group-text">Rs</span>
                      <input type="number" autoComplete="off" className="form-control"
                        name="unitprice" min={0} placeholder="0" value={addUpdateField.unitPrice}
                        onChange={(e) => setAddUpdateField({ ...addUpdateField, unitPrice: e.target.value, })} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 py-2">
                  <div className="fw-600 mb-1">GST</div>
                  <div className=" pe-0">
                    <div className="input-group">
                      <input type="number" autoComplete="off" required className="form-control" name="unitprice"
                        min={0} max={100} value={addUpdateField.Gst}
                        onChange={(e) => setAddUpdateField({ ...addUpdateField, Gst: e.target.value, })} />
                      <span className="input-group-text">%</span>
                      <span className="form-text invalid-feedback"> *required  </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex py-2">
                  <label className="fw-600 mb-1 col-md-4">Product Unit</label>
                  <div className="col-md-8 mt-1 d-flex align-items-center">
                    <div className="form-check">
                      <input className="form-check-input" defaultChecked type="radio" name="unit"
                        onChange={(e) => { setAddUpdateField({ ...addUpdateField, productUnit: "Kg", }); }} />
                      <label className="form-check-label">KG</label>
                    </div>
                    <div className="form-check ms-5">
                      <input className="form-check-input" type="radio" name="unit"
                        onChange={(e) => { setAddUpdateField({ ...addUpdateField, productUnit: "Pcs", }); }} />
                      <label className="form-check-label">Pcs</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <label className="ms-2 cursor-pointer" onClick={(e) => setIsproductModal(false)}  >  <u>Cancel</u>   </label>
            {addUpdateFlag == 1 ? (
              <button className="SaveBtn mx-2" onClick={(e) => addProductCall()}   >  Add </button>
            ) : (
              <button className="SaveBtn mx-2" onClick={(e) => UpdateProductCall()}  > Update  </button>
            )}
          </Modal.Footer>
        </Modal>
        <Modal show={deletePopup} size="md">
          <div className="text-center">
            <img src="/assets/images/redInfo.svg" className="img-fluid ps-2 mt-2" />
            <h4 className="fw-600 mt-4">    Are you sure want to delete this product?    </h4>
            <div className="col-12 text-center py-4">
              <button className="deleteBtn" onClick={() => DeleteProductCall()}> Yes </button>
              <label className="ms-4" onClick={(e) => { setDeletePopup(false); }}  > <u>Cancel</u> </label>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ProductMaster;
