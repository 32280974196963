import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ApiDeleteCall, ApiGetCall, ApiPostCall, ApiUpdateCall, CheckValidation, HideLoder, ShowLoder, ApiGetCallForPDF, ApiPostFormDataCall } from '../Component/Common';
import AlertsComp from '../Component/AlertsComp';
import { FaSort } from "react-icons/fa";

function FranchiseProduct(props) {
    const [ProductAllData, setProductAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [productid, setProductid] = useState('')
    const [EditModal, setEditModal] = useState('')
    const [deletePopup, setDeletePopup] = useState(false);
    const [addUpdateField, setAddUpdateField] = useState({
        price: 0,
        royaltyPercentage: 0,
        productMasterid: 0
    });
    const [EditFlag, setEditFlag] = useState('')
    const [searchQuery, setSearchQuery] = useState('');
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('');

    useEffect(() => {
        GetProductAllData();
    }, [])
    async function GetProductAllData() {
        setLoading(true);
        ApiGetCall("/product-master").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.rows?.length != 0) {
                        setProductAllData(responseRs?.data?.rows);
                    } else {
                        setProductAllData([]);
                    }
                }
            }
        });
    }
    async function addFProductCall(flag) {
        var isFormValid = CheckValidation({ formID: "ProductForm" });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            price: addUpdateField.price,
            royaltyPercentage: addUpdateField.royaltyPercentage,
            FranchiseId: props.FId,
            ProductMasterId: addUpdateField.productMasterid,
        });
        await ApiPostCall("/franchise-product", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Product Added Successfully."} />);
                    props.setProductModal(false)
                    setTimeout(() => {
                        setEditFlag('')
                        // props.GetProductList();
                        window.location.reload()
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }
    async function GetElementByID(id) {
        setLoading(true);
        ApiGetCall("/franchise-product/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                const sugData = responseRs?.data;
                setLoading(false);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.length != 0) {
                        setAddUpdateField({ ...addUpdateField, price: sugData.price, royaltyPercentage: sugData.royaltyPercentage, productMasterid: sugData.ProductMasterId });
                    } else {
                    }
                }
            }
        });
    }
    async function DeleteProductCall() {
        setLoading(true);
        await ApiDeleteCall("/franchise-product/" + productid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Product Deleted Successfully."} />);
                    setDeletePopup(false);
                    setTimeout(() => {
                        window.location.reload()
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });

    }
    async function UpdateFProductCall() {
        var isFormValid = CheckValidation({ formID: "ProductForm" });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            price: addUpdateField.price,
            royaltyPercentage: addUpdateField.royaltyPercentage,
            ProductMasterId: addUpdateField.productMasterid,
            FranchiseId: props.FId
        });
        await ApiUpdateCall("/franchise-product/" + productid, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Product Updated Successfully."} />);
                    setEditModal(false);
                    setEditFlag('')
                    setAddUpdateField({ ...addUpdateField, price: 0, royaltyPercentage: 0, ProductMasterId: 0 });
                    setTimeout(() => {
                        // GetUserList()
                        window.location.reload()
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    function sortAndFilterProductList() {
        let sortedList = [...props.productList];

        if (searchQuery) {
            sortedList = sortedList.filter(product => {
                const productName = product?.ProductMaster?.name?.toLowerCase();
                const category = product?.ProductMaster?.Category?.name?.toLowerCase();
                const price = product?.price?.toString()?.toLowerCase();
                const royaltyPercentage = product.royaltyPercentage.toString().toLowerCase();

                return (
                    productName.includes(searchQuery.toLowerCase()) ||
                    category.includes(searchQuery.toLowerCase()) ||
                    price.includes(searchQuery.toLowerCase()) ||
                    royaltyPercentage.includes(searchQuery.toLowerCase())
                );
            });
        }

        if (sortColumn) {
            sortedList.sort((a, b) => {
                let aValue, bValue;

                // Adjust the comparison based on the column
                if (sortColumn === 'name') {
                    aValue = a.ProductMaster?.name.toLowerCase();
                    bValue = b.ProductMaster?.name.toLowerCase();
                } else if (sortColumn === 'category') {
                    aValue = a.ProductMaster?.Category?.name.toLowerCase();
                    bValue = b.ProductMaster?.Category?.name.toLowerCase();
                } else {
                    aValue = a[sortColumn].toString().toLowerCase();
                    bValue = b[sortColumn].toString().toLowerCase();
                }

                if (sortOrder === 'asc') {
                    return aValue > bValue ? 1 : -1;
                } else {
                    return aValue < bValue ? 1 : -1;
                }
            });
        }

        return sortedList;
    }

    async function handleDownload() {
        try {
            const response = await ApiGetCallForPDF("/product-master/exportCsv"); 
            const blob = await response.blob(); 
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `products.csv`;
            document.body.appendChild(a);
            a.click();
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
      }
      
    async function handleUpload(event) {
        setLoading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file); 
        formData.append("FranchiseId", props.FId);

        try {
            const result = await ApiPostFormDataCall("/franchise-product/importCsv", formData);
            setLoading(false);

            if (!result) {
                alert("Something went wrong");
                return;
            }

            const responseRs = JSON.parse(result);
            if (responseRs.status === "success") {
                setAlerts(<AlertsComp show={true} variant="success" msg={"Product Added Successfully."} />);
                setTimeout(() => {
                    window.location.reload();
                    setAlerts(<AlertsComp show={false} />);
                }, 1500);
            } else {
                setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                setTimeout(() => {
                    setAlerts(<AlertsComp show={false} />);
                }, 1500);
            }
        } catch (error) {
            setLoading(false);
            setAlerts(<AlertsComp show={true} variant="danger" msg={"An error occurred while uploading the file."} />);
            setTimeout(() => {
                setAlerts(<AlertsComp show={false} />);
            }, 1500);
            console.error("Upload error: ", error);
        }
    }


    return (
        <div>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div>
                <div className='col-md-12 row'>
                    <div className='col-md-9'></div>
                    <div className="col-md-3 pe-0 py-1 text-end">
                        <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                            <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                            <input className="form-control marginCss" autoComplete="off" type="text" placeholder="Search" value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)} />
                        </form>
                    </div>
                </div>
                <table className="table data-table mt-3">
                    <thead className="GridHeader">
                        <tr>
                            <th scope="col" className="fw-600 cursor-pointer" onClick={() => handleSort('name')}> Name <FaSort /></th>
                            <th scope="col" className="fw-600 cursor-pointer" onClick={() => handleSort('category')}> Category <FaSort /></th>
                            <th scope="col" className="fw-600 cursor-pointer" onClick={() => handleSort('price')}> Price <FaSort /></th>
                            <th scope="col" className="fw-600 cursor-pointer" onClick={() => handleSort('royalty')}> Royalty % <FaSort /></th>
                            <th scope="col" className="fw-600 text-center"> Action  </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortAndFilterProductList().length != 0 ? (
                            sortAndFilterProductList().map((item, i) => {
                                return (
                                    <tr key={i} className="OddEvenClass tableBorderHide">
                                        <td scope="col" >{item?.ProductMaster?.name}</td>
                                        <td scope="col">{item?.ProductMaster?.Category?.name}</td>
                                        <td scope="col">{item.price}</td>
                                        <td scope="col">{item.royaltyPercentage}</td>
                                        <td scope="col" className="text-center py-12"  >
                                            <img src="/assets/images/EditIconNew.svg" title="Edit Product" className="img-fluid px-1 cursor-pointer"
                                                onClick={(e) => { setProductid(item.id); setEditFlag(1); setEditModal(true); GetElementByID(item.id); }}
                                            />
                                            <img src="/assets/images/DeleteIcon.svg" title="Delete Product" className="img-fluid px-1 cursor-pointer"
                                                onClick={(e) => { setProductid(item.id); setDeletePopup(true); }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr className="text-center">
                                <td colSpan={15}>
                                    <p>No Record Found</p>
                                    <p>
                                        <button onClick={handleDownload}>Download Product CSV</button>
                                        &nbsp;
                                        Upload a updated product 
                                        &nbsp;
                                        <input type="file" onChange={handleUpload} accept=".csv" />
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {(EditFlag == 1 ? EditModal == true : props.ProductModal == true) && (
                <Modal show={EditFlag == 1 ? EditModal : props.ProductModal} size="lg">
                    <Modal.Header closeButton onClick={(e) => { EditFlag == 1 ? setEditModal(false) : props.setProductModal(false); setAddUpdateField({ ...addUpdateField, productMasterid: 0, price: 0, royaltyPercentage: 0 }); setEditFlag('') }}>
                        <Modal.Title>
                            {EditFlag == 1 ? "Edit Franchise Product" : "Add Franchise Product"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div id="ProductForm" className="parent">
                            <div className="row">
                                <div className="col-md-6 py-2">
                                    <div className="fw-600 mb-1">Product Select</div>
                                    <select
                                        required
                                        value={addUpdateField.productMasterid}
                                        name="Category"
                                        onChange={(e) => {
                                            const selectedProductId = e.target.value;
                                            const selectedOption = ProductAllData.find(item => item.id == selectedProductId);
                                            const selectedPrice = selectedOption ? selectedOption.price : 0; // Set a default price if no option is found
                                            setAddUpdateField({
                                                ...addUpdateField,
                                                productMasterid: selectedProductId,
                                                price: selectedPrice
                                            });
                                        }}
                                    >
                                        <option value="0">Select</option>
                                        {ProductAllData.map((item, j) => (
                                            <option value={item.id} key={j}>
                                                {item.name}&nbsp;({item.Category.name})
                                            </option>
                                        ))}
                                    </select>
                                    <span className="form-text invalid-feedback">  *required  </span>
                                </div>
                                <div className="col-md-6 py-2">
                                    <div className="fw-600 mb-1">Price</div>
                                    <div className=" pe-0">
                                        <div className="input-group">
                                            <span className="input-group-text">Rs</span>
                                            <input type="number" autoComplete="off" className="form-control"
                                                name="unitprice" min={0} placeholder="0" value={addUpdateField.price}
                                                onChange={(e) => setAddUpdateField({ ...addUpdateField, price: e.target.value, })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 py-2">
                                    <div className="fw-600 mb-1">Royalty Percentage</div>
                                    <div className=" pe-0">
                                        <div className="input-group">
                                            <input type="number" autoComplete="off" required className="form-control" name="unitprice"
                                                min={0} max={100} value={addUpdateField.royaltyPercentage}
                                                onChange={(e) => setAddUpdateField({ ...addUpdateField, royaltyPercentage: e.target.value, })} />
                                            <span className="input-group-text">%</span>
                                            <span className="form-text invalid-feedback"> *required  </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <label className="ms-2 cursor-pointer" onClick={(e) => { EditFlag == 1 ? setEditModal(false) : props.setProductModal(false); setAddUpdateField({ ...addUpdateField, productMasterid: 0, price: 0, royaltyPercentage: 0 }); setEditFlag('') }}  >  <u>Cancel</u>   </label>
                        {EditFlag == 1 ? (
                            <button className="SaveBtn mx-2" onClick={(e) => UpdateFProductCall()}  > Update  </button>
                        ) : (
                            <button className="SaveBtn mx-2" onClick={(e) => addFProductCall()}   >  Add </button>
                        )}
                    </Modal.Footer>
                </Modal>
            )}
            <Modal show={deletePopup} size="md">
                <div className="text-center">
                    <img src="/assets/images/redInfo.svg" className="img-fluid ps-2 mt-2" />
                    <h4 className="fw-600 mt-4">    Are you sure want to delete this product?    </h4>
                    <div className="col-12 text-center py-4">
                        <button className="deleteBtn" onClick={() => DeleteProductCall()}> Yes </button>
                        <label className="ms-4" onClick={(e) => { setDeletePopup(false); }}  > <u>Cancel</u> </label>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default FranchiseProduct