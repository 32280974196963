import React, { useEffect, useState } from 'react'
import { ApiGetCall, HideLoder, ShowLoder, getUrlParameter } from '../Component/Common'
import FranchiseProduct from './FranchiseProduct';
import FranchiseUser from './FranchiseUser';

function ManageFranchise() {
  var FId = getUrlParameter('id')
  const [loading, setLoading] = useState(false);
  const [Alerts, setAlerts] = useState("");
  const [FranchiseData, setFranchiseData] = useState('')
  const [productList, setProductList] = useState([]);
  const [ProductModal, setProductModal] = useState(false);
  const [UserModal, setUserModal] = useState(false);
  const [Tabs, setTabs] = useState({
    ProductTab: "active",
    UserTab: "",
  });
  useEffect(() => {
    GetDataById();
    GetProductList();
  }, [])

  async function GetDataById() {
    setLoading(true);
    ApiGetCall("/franchise/" + FId).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        const sugData = responseRs?.data;
        setLoading(false);
        if (responseRs.status == "success") {
          if (responseRs?.data?.length != 0) {
            setFranchiseData(sugData)
          } else {
            setFranchiseData([])
          }
        }
      }
    });
  }
  async function GetProductList() {
    setLoading(true);
    ApiGetCall("/franchise-product?FranchiseId=" + FId).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        setLoading(false);
        if (responseRs.status == "success") {
          if (responseRs?.data?.rows?.length != 0) {
            setProductList(responseRs?.data?.rows);
          } else {
            setProductList([]);
          }
        }
      }
    });
  }

  return (
    <main className="main-container">
      {Alerts}
      {loading ? <ShowLoder /> : <HideLoder />}
      <div>
        <div className="row col-12 align-items-center pb-1">
          <div className="col-md-9">
            <h1 className="PageHeading mb-0">Manage Franchise</h1>
          </div>
        </div>
        <div className='row col-12 pt-2'>
          <div className='col-md-5  ps-3 pe-1'>
            <div className='GridBox h-100 px-3 pb-3 pt-1'>
              <div className="col-12 p-1 pe-0 row">
                <div className="col-md-6  font-14"> Franchise Name : </div>
                <div className="col-md-6 fw-600 font-14">{FranchiseData.name || '-'}</div>
              </div>
              <div className="col-12 p-1 pe-0 row">
                <div className="col-md-6  font-14"> Location : </div>
                <div className="col-md-6 fw-600 font-14">{FranchiseData.location || '-'}</div>
              </div>
              <div className="col-12 p-1 pe-0 row">
                <div className="col-md-6  font-14"> Owner Name : </div>
                <div className="col-md-6 fw-600 font-14">{FranchiseData.ownerName || '-'}</div>
              </div>
              <div className="col-12 p-1 pe-0 row">
                <div className="col-md-6  font-14"> Owner Number : </div>
                <div className="col-md-6 fw-600 font-14">{FranchiseData.ownerNumber || '-'}</div>
              </div>
              <div className="col-12 p-1 pe-0 row">
                <div className="col-md-6  font-14"> Owner Email : </div>
                <div className="col-md-6 fw-600 font-14">{FranchiseData.ownerEmail || '-'}</div>
              </div>
              <div className="col-12 p-1 pe-0 row">
                <div className="col-md-6 font-14 "> GST Number : </div>
                <div className="col-md-6 fw-600 font-14">{FranchiseData.gstNumber || '-'}</div>
              </div>
            </div>
          </div>
          <div className='col-md-7  pe-3 ps-1'>
            <div className='GridBox h-100 p-3'>
              In Progress
            </div>
          </div>
        </div>

        <div className="GridBox p-3 mt-3">
          <div className="greyBox p-3 data-table-container">
            <div className='row col-12'>
              <div className="col-xl-8 col-md-8">
                <ul className="nav nav-tabs">
                  <li className="col-md-3 nav-item text-center cursor-pointer">
                    <a className={`nav-link linkclass TabNavlink ${Tabs.ProductTab}`}
                      onClick={(e) => { setTabs({ ...Tabs, ProductTab: "active", UserTab: "" }); GetProductList(); }}>
                      Product
                    </a>
                  </li>
                  <li className="col-md-3 nav-item  text-center cursor-pointer mx-2 marginForMobile">
                    <a className={`nav-link linkclass TabNavlink ${Tabs.UserTab}`}
                      onClick={(e) => { setTabs({ ...Tabs, ProductTab: "", UserTab: "active", }); }}>
                      User
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 pe-0 text-end">
                <label className="BorderBtn text-center me-0 cursor-pointer" title="Add Building"
                  onClick={() => { Tabs.ProductTab == 'active' ? setProductModal(true) : setUserModal(true); }}>
                  {Tabs.ProductTab == 'active' ? 'Add Franchise Product' : 'Add Franchise User'}
                  <img src="/assets/images/AddInventory.svg" className="img-fluid ps-2" />
                </label>
              </div>
            </div>

            {Tabs.ProductTab == 'active' && (
              <FranchiseProduct productList={productList} FId={FId} ProductModal={ProductModal} setProductModal={setProductModal} />
            )}

            {Tabs.UserTab == 'active' && (
              <FranchiseUser FId={FId} UserModal={UserModal} setUserModal={() => setUserModal(false)} />
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

export default ManageFranchise