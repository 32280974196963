import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ApiGetCall, ApiPostCall, CheckValidation, HideLoder, ShowLoder } from '../Component/Common';
import AlertsComp from '../Component/AlertsComp';

function OrderList() {
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [SearchForCname, setSearchForCname] = useState("");
    const [SuggestionBoxArray, setSuggestionBoxArray] = useState([]);
    const [CnameSBox, setCnameSBox] = useState({
        ShowSuggestion: "d-none",
    });
    const [cname, setCname] = useState('')
    const [Cid, setCid] = useState('')
    const [numberError, setNumberError] = useState('')
    const [PayMethod, setPayMethod] = useState('')
    const location = useLocation();
    const SelectedData = location.state && location.state.allSelectedItems;
    function handleGoBack() {
        window.history.back();
    }
    const totalPrice = SelectedData.reduce((total, item) => total + Number((item.quantity * item.price).toFixed(0)), 0);
    const DiscountPrice = 0;
    const taxPrice = 0;

    async function showContactData(searchString) {
        if (searchString == "") {
            searchString = null;
        }
        // if (searchString && searchString.length >=2) {
        setCnameSBox({ ...CnameSBox, ShowSuggestion: "" });
        await ApiGetCall("/customer?searchQuery=" + searchString).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var sugData = responseRs?.data?.rows;
                var sugArray = [];
                var i = 1;
                if (sugData.length != 0) {
                    for (var i = 0; i < sugData.length; i++) {
                        sugArray.push(
                            <div className="col-md-12 d-flex justify-content-between font-14 brdr-Btm" key={i} id={sugData[i].id} name={sugData[i].name} number={sugData[i].number} onClick={(e) => showContactClick(e)} style={{ padding: "8px 15px" }}>
                                <div> <b>{sugData[i].name || '-'}</b></div>
                                <div><b>{sugData[i].number || '-'}</b></div>
                            </div>
                        )
                    }
                    setSuggestionBoxArray(sugArray);
                } else {
                    setCnameSBox({ ...CnameSBox, ShowSuggestion: "d-none" });
                    // sugArray.push(
                    //     <div className="col-12 text-center" key={i}>  <label>No Record Found</label>  </div>
                    // );
                    // setSuggestionBoxArray(sugArray);
                }
            }
        });
        // }
    }

    function showContactClick(e) {
        setCnameSBox({ ...CnameSBox, ShowSuggestion: 'd-none' });
        var id = parseInt(e.currentTarget.attributes[1].value);
        var name = e.currentTarget.attributes[2].value;
        var number = e.currentTarget.attributes[3].value;
        setCname(name)
        setCid(id)
        setSearchForCname(number)
    }

    async function SaveCall() {
        var isFormValid = CheckValidation({ formID: "NameForm" });
        if (SearchForCname == '') {
            setNumberError('*required');
            return;
        } else if (SearchForCname.length != 10) {
            setNumberError('Enter valid number');
            return;
        }
        if (!isFormValid) return;
        var vArray = [];
        {
            SelectedData.map((item, i) => {
                var vjson = {};
                vjson['FranchiseProductId'] = item.FranchiseProductId;
                vjson['quantity'] = item.quantity;
                vArray.push(vjson)
            })
        }
        setLoading(true);
        var raw = JSON.stringify({
            CustomerId: Cid,
            CustomerName: cname,
            CustomerNumber: SearchForCname,
            discount: 0,
            paymentMode: PayMethod,
            items: vArray
        });
        await ApiPostCall("/invoice", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Order Added Successfully."} />);
                    setTimeout(() => {
                        window.location = '/menu'
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    }

    const handlePrintLink = async () => {
        var isFormValid = CheckValidation({ formID: "NameForm" });
        if (SearchForCname == '') {
            setNumberError('*required');
            return;
        } else if (SearchForCname.length != 10) {
            setNumberError('Enter valid number');
            return;
        }
        if (!isFormValid) return;
        var vArray = [];
        {
            SelectedData.map((item, i) => {
                var vjson = {};
                vjson['FranchiseProductId'] = item.FranchiseProductId;
                vjson['quantity'] = item.quantity;
                vArray.push(vjson)
            })
        }
        setLoading(true);
        var raw = JSON.stringify({
            CustomerId: Cid,
            CustomerName: cname,
            CustomerNumber: SearchForCname,
            discount: 0,
            paymentMode: PayMethod,
            items: vArray
        });

        await ApiPostCall("/invoice", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    ApiGetCall("/invoice/print/" + responseRs.data.id)
                        .then(html => {
                            const iframe = document.createElement('iframe');
                            iframe.style.display = 'none';
                            document.body.appendChild(iframe);

                            const iframeDocument = iframe.contentDocument;
                            iframeDocument.open();
                            iframeDocument.write(html);
                            iframeDocument.close();

                            iframe.contentWindow.print();
                            setTimeout(() => {
                                window.location = '/menu'
                                setAlerts(<AlertsComp show={false} />);
                            }, 1500);
                        }).catch(error => {
                            console.error('Error printing PDF:', error);
                        });
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
            }
        });
    };
    return (
        <div>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div>
                <div className='align-items-center d-flex pb-1'><label onClick={handleGoBack} style={{ fontSize: '20px' }}> <FontAwesomeIcon icon={faAngleLeft} style={{ height: "20px", width: "20px" }} className='pe-1' /></label>Back</div>
            </div>
            <div className='GridBox p-3'>
                <label className='fw-600 pb-1'>Customer Details</label>
                <div className="col-md-12 pe-0 text-end">
                    <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                        <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                        <input className="form-control marginCss" autoComplete="off" type="text" placeholder="Search Mobile Number"
                            value={SearchForCname} onChange={(e) => { setSearchForCname(e.target.value) }}
                            onKeyUp={(e) => showContactData(e.target.value)} onMouseUp={(e) => showContactData(e.target.value)} />
                        <div className={`SuggestionBoxDiv ${CnameSBox.ShowSuggestion}`} style={{ zIndex: '1' }} onMouseLeave={() => setCnameSBox({ ...CnameSBox, ShowSuggestion: "d-none" })}>
                            {SuggestionBoxArray}
                        </div>
                    </form>
                    {numberError && <div className="redText text-start ps-3 py-1"> {numberError} </div>}
                </div>
                <div className='my-2 parent' style={{ height: '35px' }} id="NameForm" >
                    <input type="text" className="form-control" required placeholder='Enter Customer Name' autoComplete="off" name="name"
                        value={cname} onChange={(e) => setCname(e.target.value)} />
                    <span className="form-text invalid-feedback ps-3"> *required{" "} </span>
                </div>
            </div>
            <div className='greyBoxForInvoice my-3' >
                <div className='col-12 row p-1'>
                    <div className='col-5 fw-600'>Product</div>
                    <div className='col-2 fw-600'>Qty</div>
                    <div className='col-3 fw-600'>Price</div>
                    <div className='col-2 text-end p-0 fw-600'>Amount</div>
                </div>
                <div className='dash my-1'></div>
                <div className='col-12 row p-1'>
                    {SelectedData.map((item, i) => {
                        return (
                            <>
                                <div className='col-5'>{item.ProductMaster.name + ' (' + item.ProductMaster.unit + ')'}</div>
                                <div className='col-2'>{item.quantity}</div>
                                <div className='col-3'>{item.price.toFixed(0)}</div>
                                <div className='col-2 text-end p-0'>{(item.quantity * item.price).toFixed(0)}</div>
                            </>
                        );
                    })}
                </div>
                <div className='dash my-1 d-flex justify-content-center'></div>
                <div className='col-12 row p-1'>
                    <div className='col-6 fw-600'>Sub Total</div>
                    <div className='col-6 text-end p-0 fw-600'>{totalPrice.toFixed(0)}</div>
                    {/* <div className='col-6 fw-600'>Discount</div>
                    <div className='col-6 text-end p-0 fw-600'>{DiscountPrice.toFixed(2)}</div> */}
                    <div className='col-6 fw-600'>Tax</div>
                    <div className='col-6 text-end p-0 fw-600'>{taxPrice.toFixed(0)}</div>
                    <div className='col-6 fw-600'>Total</div>
                    <div className='col-6 text-end p-0 fw-600'><label>RS.</label>{totalPrice.toFixed(0)}</div>
                </div>
            </div>

            <div className='col-md-12 justify-content-center m-3 d-flex align-items-center'>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name='pay' onChange={(e) => setPayMethod('online')} />
                    <label className="form-check-label">
                        Online
                    </label>
                </div>
                <div className="form-check ms-4">
                    <input className="form-check-input" type="radio" name='pay' onChange={(e) => setPayMethod('cash')} />
                    <label className="form-check-label">
                        Cash
                    </label>
                </div>
            </div>
            <div className='col-12 text-center py-3'>
                <button className="SaveBtnUser px-4" onClick={SaveCall}> Save Order </button>
                <button className="SaveBtnUser px-4 mx-2" onClick={handlePrintLink}> Save & Print </button>
            </div>
        </div>
    )
}

export default OrderList