import React, { useEffect, useState } from 'react'
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { ApiGetCall, HideLoder, ShowLoder, YYYYMMDD, formatDateToMMDDYYYY } from '../Component/Common';
import Pagination from 'react-js-pagination';

function Reports() {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);
    const [startDate, setStartDate] = useState(oneMonthAgo);
    const [endDate, setEndDate] = useState(today);
    const [loading, setLoading] = useState(false);
    const [GetDataList, setGetDataList] = useState([]);
    const [GetAllOverCount, setGetAllOverCount] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const itemsPerPage = LimitForPagination;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const DataForGrid = GetDataList?.slice(indexOfFirstItem, indexOfLastItem);
    const showPagination = GetDataList.length > 0;
    useEffect(() => {
        GetAllOverData();
    }, [])
    useEffect(() => {
        GetReportsData()
    }, [])

    async function GetReportsData() {
        setLoading(true);
        ApiGetCall(`/report/franchise/sale?startDate=${YYYYMMDD(startDate)}&endDate=${YYYYMMDD(endDate)}`).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.length != 0) {
                        setGetDataList(responseRs?.data);
                    } else {
                        setGetDataList([]);
                    }
                }
            }
        });
    }
    async function GetAllOverData() {
        ApiGetCall("/report/franchise/data").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    if (responseRs.length != 0) {
                        setGetAllOverCount(responseRs.data);
                    } else {
                        setGetAllOverCount([]);
                    }
                }
            }
        });
    }

    function handleStartDateChange(date) {
        setStartDate(date);
    };
    function handleEndDateChange(date) {
        setEndDate(date);
    };
    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row col-12 pe-0 align-items-center pb-3'>
                <div className='col-md-6'>
                    <h1 className="PageHeading">Reports</h1>
                </div>
                <div className="col-md-6 text-end row px-0">
                    <div className="col-md-5 d-flex  align-items-center py-1 px-0">
                        <label className="px-3">From</label>
                        <ReactDatePicker
                            className="form-control me-4 text-center"
                            selected={startDate}
                            onChange={(e) => { handleStartDateChange(e); }}
                            dateFormat="MM/dd/yyyy"
                        />
                    </div>
                    <div className="col-md-5 d-flex  align-items-center py-1 px-0">
                        <label className="px-3">To</label>
                        <ReactDatePicker
                            className="form-control me-4 text-center"
                            selected={endDate}
                            onChange={(e) => { handleEndDateChange(e); }}
                            dateFormat="MM/dd/yyyy"
                            maxDate={today}
                        />
                    </div>
                    <div className='col-md-2 py-1'>
                        <button className="SaveBtn mx-2 px-3" onClick={(e) => GetReportsData()} >  Apply </button>
                    </div>
                </div>
            </div>

            <div>
                <div className='col-md-12 d-flex flex-wrap'>
                    <div className='col-md-3 col-sm-6 px-2 mb-3'>
                        <div className='GridBox text-center p-3'>
                            <div className='font-16'>Today's Sale</div>
                            <div className='fw-600 font-20'>{GetAllOverCount.today}</div>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-6 px-2 mb-3'>
                        <div className='GridBox text-center p-3'>
                            <div className='font-16'>Last Week</div>
                            <div className='fw-600 font-20'>{GetAllOverCount.lastWeek}</div>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-6 px-2 mb-3'>
                        <div className='GridBox text-center p-3'>
                            <div className='font-16'>Last Month</div>
                            <div className='fw-600 font-20'>{GetAllOverCount.lastMonth}</div>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-6 px-2 mb-3'>
                        <div className='GridBox text-center p-3'>
                            <div className='font-16'>This Year</div>
                            <div className='fw-600 font-20'>{GetAllOverCount.thisYear}</div>
                        </div>
                    </div>
                </div>

                <div className="GridBox p-3 pt-5">
                    <div className="greyBox p-3 data-table-container">
                        <table className="table data-table">
                            <thead className="GridHeader">
                                <tr>
                                    <th scope="col" className="fw-600">Invoice #</th>
                                    <th scope="col" className="fw-600">Customer Name</th>
                                    <th scope="col" className="fw-600">Number</th>
                                    <th scope="col" className="fw-600">Total</th>
                                    <th scope="col" className="fw-600">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DataForGrid.length != 0 ? (
                                    DataForGrid.map((item, i) => {
                                        return (
                                            <tr key={i} className="OddEvenClass tableBorderHide">
                                                <td scope="col">{item.id}</td>
                                                <td scope="col">{item.name}</td>
                                                <td scope="col">{item.number}</td>
                                                <td scope="col">{item.total}</td>
                                                <td scope="col">{formatDateToMMDDYYYY(item.date)}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr className="text-center">  <td colSpan={15}>No Record Found</td>  </tr>
                                )}
                            </tbody>

                        </table>
                    </div>
                    {showPagination && (
                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={GetDataList.length}
                            pageRangeDisplayed={5}
                            onChange={(e) => setCurrentPage(e)}
                            color="primary"
                            lastPageText=""
                            firstPageText=""
                            itemClassFirst="MasterClass"
                            itemClassPrev="MasterClass"
                            itemClassNext="MasterClass"
                            itemClassLast="MasterClass"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Reports