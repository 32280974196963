import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { ApiGetCall, HideLoder, ShowLoder, formatDate, formatTime, ApiGetCallForPDF } from '../Component/Common';
import { useNavigate } from 'react-router-dom';

function Orders() {
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const [selectedTab, setSelectedTab] = useState(1);
    const [OrdersList, setOrdersList] = useState([])
    function ShowHideDiv(itemId) {
        setVisibleID(prevID => (prevID == itemId ? null : itemId));
    }
    const [visibleID, setVisibleID] = useState(null);
    const navigate = useNavigate('')


    useEffect(() => {
        GetOrdersList();
    }, []);

    async function GetOrdersList() {
        setLoading(true);
        ApiGetCall("/invoice").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.status == "success") {
                    if (responseRs?.data?.rows?.length != 0) {
                        setOrdersList(responseRs?.data?.rows);
                    } else {
                        setOrdersList([]);
                    }
                }
            }
        });
    }

    const handlePrintLink = async (id) => {
        await ApiGetCall("/invoice/print/" + id)
            .then(html => {
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                document.body.appendChild(iframe);

                const iframeDocument = iframe.contentDocument;
                iframeDocument.open();
                iframeDocument.write(html);
                iframeDocument.close();

                iframe.contentWindow.print();
            }).catch(error => {
                console.error('Error printing PDF:', error);
            });
    };

    const handleDownloadLink = async (id) => {
        try {
            const response = await ApiGetCallForPDF("/invoice/download/" + id); 
            const blob = await response.blob(); 
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `invoice-#${id}.pdf`;
            document.body.appendChild(a);
            a.click();
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <div className='p-0'>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="row align-items-center pb-1">
                <div className="col-md-9">
                    <h1 className="PageHeading mb-0"> Orders</h1>
                </div>
            </div>
            {/* <div className='d-flex'>
                <div className={`GridBoxForTabel py-2 px-3 mx-1 ${selectedTab == 1 ? 'greenColor' : 'whiteColor'
                    }`} onClick={() => setSelectedTab(1)}>On Process</div>
                <div className={`GridBoxForTabel py-2 px-3 mx-1 ${selectedTab == 2 ? 'greenColor' : 'whiteColor'
                    }`} onClick={() => setSelectedTab(2)}>Completed</div>
            </div> */}

            {selectedTab == 1 && (
                <div className='my-3 row'>
                    {OrdersList.length != 0 ? (
                        OrdersList.map((item, i) => {
                            const isDivVisible = item.id === visibleID;
                            return (
                                <div className='col-xl-6 col-md-6 col-12 py-2'>
                                    <div className='GridBox h-100 px-3 py-2 my-2'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div>
                                                <div><label className='fw-600 greenColortext'>{item.Customer.name}</label></div>
                                                <div><label className='font-13 grayColor'>Order #{item.id}</label></div>
                                            </div>
                                            <div>
                                                <div><label className='font-13 grayColor'>{formatDate(item.createdAt)}</label></div>
                                                <div><label className='font-13 grayColor' style={{ float: 'right' }}>{formatTime(item.createdAt)}</label></div>
                                            </div>
                                        </div>
                                        <div className='col-12 text-end p-0 m-0'>
                                            <FontAwesomeIcon icon={faAngleDown} title='Show More Details' className={`cursor-pointer img-fluid ${isDivVisible ? "d-none" : ""}`} onClick={() => { ShowHideDiv(item.id) }} style={{ height: '15px' }} />
                                            <FontAwesomeIcon icon={faAngleUp} title='Hide More Details' className={`img-fluid cursor-pointer ${isDivVisible ? "" : "d-none"}`} onClick={() => { ShowHideDiv(item.id) }} style={{ height: '15px' }} />
                                        </div>
                                        {isDivVisible && (
                                            <>
                                                <hr className='my-0' />

                                                <div>
                                                    <div className='font-13' >
                                                        <div className='col-12 row p-1 grayColor'>
                                                            <div className='col-5 fw-600'>Product</div>
                                                            <div className='col-2 fw-600'>Qty</div>
                                                            <div className='col-3 fw-600'>Price</div>
                                                            <div className='col-2 text-end p-0 fw-600'>Amount</div>
                                                        </div>
                                                        <hr className='m-0' />
                                                        {item.InvoiceItems.length != 0 ? (
                                                            item.InvoiceItems.map((itemm, i) => {
                                                                return (
                                                                    <div className='col-12 row p-1'>
                                                                        <div className='col-5'>{itemm.FranchiseProduct.ProductMaster.name + ' (' + itemm.FranchiseProduct.ProductMaster.unit + ')'}</div>
                                                                        <div className='col-2'>{itemm.quantity}</div>
                                                                        <div className='col-3'>{itemm.unitPrice}</div>
                                                                        <div className='col-2 text-end p-0'>{itemm.totalPrice}</div>
                                                                    </div>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr className="text-center">  <td colSpan={15}>No Record Found</td>  </tr>
                                                        )}
                                                        <hr className='m-0' />
                                                        <div className='col-12 row p-1'>
                                                            <div className='col-6 fw-600'>Sub Total</div>
                                                            <div className='col-6 text-end p-0 fw-600'>{item.subTotal}</div>
                                                            {/* <div className='col-6 fw-600'>Discount</div>
                                                            <div className='col-6 text-end p-0 fw-600'>{item.discount}</div> */}
                                                            <div className='col-6 fw-600'>Tax</div>
                                                            <div className='col-6 text-end p-0 fw-600'>{item.totalTax}</div>
                                                            <div className='col-6 fw-600'>Total</div>
                                                            <div className='col-6 text-end p-0 fw-600'><label>RS.</label>{item.total}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='d-flex py-2 align-items-center justify-content-center'>
                                                    <div className='GridBoxForTabel py-2 px-3 mx-1 greenColor-border-button text-center fw-600 cursor-pointer' onClick={(e) => navigate('/menu?id=' + item.id, { state: { item: item.InvoiceItems } })}>Edit Orders</div>
                                                    <div className='GridBoxForTabel py-2 px-3 mx-1 greenColor-border-button text-center fw-600 cursor-pointer' onClick={(e) => handlePrintLink(item.id)}>Print</div>
                                                    <div className='GridBoxForTabel py-2 px-3 mx-1 greenColor-border-button text-center fw-600 cursor-pointer' onClick={(e) => handleDownloadLink(item.id)}>Download</div>
                                                </div>
                                            </>)}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <tr className="text-center">  <td colSpan={15}>No Record Found</td>  </tr>
                    )}
                </div>
            )}
        </div>
    )
}

export default Orders