import { Alert } from "react-bootstrap";

export default function AlertsComp(props) {
    return (
        <>
            {props.variant == "success" ?
                <Alert show={props.show} variant="success" className="mt-3 ml-3 reactalert-success">
                    {props.msg}
                </Alert>
                : props.variant == "danger" ?
                    <Alert show={props.show} variant="danger" className="mt-3 ml-3 reactalert-danger">
                        {props.msg}
                    </Alert>
                    :
                    <></>
            }

        </>
    )
}