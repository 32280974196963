import Cookies from 'js-cookie'
import React, { useEffect } from 'react'

function SignOut() {
    useEffect(() => {
        Cookies.remove('token')
        Cookies.remove('flag')
    }, [])
  return (
    <>
            <div className="position-relative MainDiv">
                <div className="card mx-auto">
                    <div className="p-4 row">
                        <div className="pt-4" style={{ color: '#495057' }}><h4>You are Logged Out</h4></div>
                        <div className="pt-2" style={{ color: '#878a99' }}><h6>Thank you for using G-fresh</h6></div>
                        <div className='text-center pt-3'>
                            <button className='SaveBtn' onClick={() => window.location.href = '/login'} style={{ width: '90%' }}>Sign In</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default SignOut