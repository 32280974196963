import Dashboard from './Adminside/Dashboard';
import ProductMaster from './Adminside/ProductMaster';
import Sidemenu from './Adminside/Sidemenu';
import './App.css';
import '../src/style/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from 'react';
import ManageCategory from './Adminside/ManageCategory';
import Franchise from './Adminside/Franchise';
import ManageFranchise from './Adminside/ManageFranchise';
import AdminLogin from './Login/AdminLogin';
import Cookies from 'js-cookie';
import UserDashboard from './Userside/UserDashboard';
import Menu from './Userside/Menu';
import OrderList from './Userside/OrderList';
import ErrorPage from './Component/ErrorPage';
import Orders from './Userside/Orders';
import SignOut from './Component/SignOut';
import Reports from './Userside/Reports';

function App() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
  var loginaccess = Cookies.get('token')
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }
  // useEffect(() => {
  //   console.log(loginaccess)
  //   if (window.location.hostname === "localhost" && window.location.port === "3000") {
  //     Cookies.remove('flag');
  //   }
  // }, [])
  return (
    <>

      {loginaccess != '' && loginaccess != undefined && loginaccess != null ?
        <Router>
          <Sidemenu>
            {Cookies.get('flag') == 1 ?
              <Routes>
                <Route exact path="/admin/dashboard" element={<Dashboard />} />
                <Route exact path="/admin/Category" element={<ManageCategory />} />
                <Route exact path="/admin/ProductMaster" element={<ProductMaster />} />
                <Route exact path="/admin/Franchise" element={<Franchise />} />
                <Route exact path="/admin/Manage-Franchise" element={<ManageFranchise />} />
              </Routes>
              :
              <Routes>
                <Route exact path="*" element={<ErrorPage />} />
                <Route exact path="/" element={<Menu />} />
                <Route exact path="/menu" element={<Menu />} />
                <Route exact path="/order-list" element={<OrderList />} />
                <Route exact path="/orders" element={<Orders />} />
                <Route exact path="/reports" element={<Reports />} />
              </Routes>
            }
          </Sidemenu>
        </Router>
        :
        <Router>
          <Routes>
            <Route exact path="/admin" element={<AdminLogin />} />
            <Route exact path="/admin/adminlogin" element={<AdminLogin />} />
            <Route exact path="/" element={<AdminLogin />} />
            <Route exact path="/login" element={<AdminLogin />} />
            <Route exact path="/signout" element={<SignOut />} />
          </Routes>
        </Router>
      }
    </>

  );
}

export default App;